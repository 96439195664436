import React from 'react';

export const ArrowDropUpIcon = ({
  width = '16',
  height = '16',
  color = 'var(--gray-10)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.28324 9.33327C5.98324 9.33327 5.7749 9.19716 5.65824 8.92493C5.54157 8.65271 5.58879 8.41105 5.7999 8.19993L7.53324 6.4666C7.5999 6.39993 7.67212 6.34993 7.7499 6.3166C7.82768 6.28327 7.91101 6.2666 7.9999 6.2666C8.08879 6.2666 8.17212 6.28327 8.2499 6.3166C8.32768 6.34993 8.3999 6.39993 8.46657 6.4666L10.1999 8.19993C10.411 8.41105 10.4582 8.65271 10.3416 8.92493C10.2249 9.19716 10.0166 9.33327 9.71657 9.33327H6.28324Z"
        fill={color}
      />
    </svg>
  );
};
export const ArrowDropDownIcon = ({
  width = '16',
  height = '16',
  color = 'var(--gray-10)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.03324 9.53366L6.2999 7.80033C6.08879 7.58921 6.04157 7.34755 6.15824 7.07533C6.2749 6.8031 6.48324 6.66699 6.78324 6.66699H10.2166C10.5166 6.66699 10.7249 6.8031 10.8416 7.07533C10.9582 7.34755 10.911 7.58921 10.6999 7.80033L8.96657 9.53366C8.8999 9.60033 8.82768 9.65033 8.7499 9.68366C8.67212 9.71699 8.58879 9.73366 8.4999 9.73366C8.41101 9.73366 8.32768 9.71699 8.2499 9.68366C8.17212 9.65033 8.0999 9.60033 8.03324 9.53366Z"
        fill={color}
      />
    </svg>
  );
};
export const ArrangeIcon = ({
  width = '16',
  height = '16',
  color = 'var(--gray-60)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.42498 10C8.97498 10 8.66248 9.79586 8.48748 9.38752C8.31248 8.97919 8.38331 8.61669 8.69998 8.30002L11.3 5.70002C11.4 5.60002 11.5083 5.52502 11.625 5.47502C11.7416 5.42502 11.8666 5.40002 12 5.40002C12.1333 5.40002 12.2583 5.42502 12.375 5.47502C12.4916 5.52502 12.6 5.60002 12.7 5.70002L15.3 8.30002C15.6166 8.61669 15.6875 8.97919 15.5125 9.38752C15.3375 9.79586 15.025 10 14.575 10H9.42498Z"
        fill={color}
      />
      <path
        d="M11.3 18.3L8.69998 15.7C8.38331 15.3833 8.31248 15.0208 8.48748 14.6125C8.66248 14.2042 8.97498 14 9.42498 14H14.575C15.025 14 15.3375 14.2042 15.5125 14.6125C15.6875 15.0208 15.6166 15.3833 15.3 15.7L12.7 18.3C12.6 18.4 12.4916 18.475 12.375 18.525C12.2583 18.575 12.1333 18.6 12 18.6C11.8666 18.6 11.7416 18.575 11.625 18.525C11.5083 18.475 11.4 18.4 11.3 18.3Z"
        fill={color}
      />
    </svg>
  );
};
