import { convertSecondToTimeText } from '@asset/function/commonFunctions';
import BasicArrowTooltip from '../tool-tip/BasicArrowTooltip';
import { useTranslation } from 'next-i18next';

type textFormat = '' | 'percent' | 'time' | 'problemCount';
export interface LineGraphWithTextProps {
  textFormat: textFormat[];
  graphData: {
    id: number;
    total: number;
    value: number;
    tooltipTitle?: string;
    onClickEvent?: null | (() => void);
  }[];
  graphColorCriteriaTable: {
    [key: string]: string;
  };
  graphDomTotalWidth?: number; //rem 단위
}

const LineGraphWithText = ({
  textFormat,
  graphData,
  graphColorCriteriaTable,
  graphDomTotalWidth = null,
}: LineGraphWithTextProps) => {
  const { ready, t } = useTranslation(['common']);
  const getText = (number, format) => {
    if (format === 'percent') {
      return number + '%';
    } else if (format === 'time') {
      const { timeFormattingString } = convertSecondToTimeText(number, 1, 0, {
        hour: `${ready ? t('common:unit.time2.hour') : ''}`,
        minute: `${ready ? t('common:unit.time1.minute') : ''}`,
        second: null,
      });
      return timeFormattingString;
    } else if (format === 'problemCount') {
      return (
        number +
        ' ' +
        `${
          ready ? t('common:unit.count.problemCount2', { count: number }) : ''
        }`
      );
    }
    return '';
  };

  const getGraphColor = (number: number) => {
    const criteriaList: number[] = Object.keys(graphColorCriteriaTable)
      .map((criteria) => {
        return Number(criteria);
      })
      .sort();
    for (let i = 0; i < criteriaList.length; i++) {
      const criteria = criteriaList[i];
      if (number === criteria) {
        return graphColorCriteriaTable[criteriaList[i]];
      }
      if (number < criteria) {
        if (i - 1 < 0) {
          return graphColorCriteriaTable[criteriaList[i]];
        }
        return graphColorCriteriaTable[criteriaList[i - 1]];
      }
    }
    return graphColorCriteriaTable[criteriaList[criteriaList.length - 1]];
  };

  const graphTotalSum = graphData.reduce((sum, currGraphInfo) => {
    if (typeof currGraphInfo.total !== 'number') {
      return null;
    }
    return sum + currGraphInfo.total;
  }, 0);

  const graphValueSum = graphData.reduce((sum, currGraphInfo) => {
    if (typeof currGraphInfo.value !== 'number') {
      return null;
    }
    return sum + currGraphInfo.value;
  }, 0);

  const totalWeightedValueSum = graphData.reduce((sum, currGraphInfo) => {
    const currGraphRate = (currGraphInfo.value / currGraphInfo.total) * 100;
    let weightedRate = (currGraphRate * currGraphInfo.total) / graphTotalSum;
    if (!weightedRate || isNaN(weightedRate)) {
      weightedRate = 0;
    }
    return sum + weightedRate;
  }, 0);

  return (
    <div className={`flex flex-col items-end`}>
      <div className={`flex gap-1 w-full`}>
        {graphData.map((graph, index) => {
          const graphValueRate =
            !graph.total && graph.value
              ? 100
              : graph.value && graph.total
              ? Math.floor((graph.value / graph.total) * 100)
              : 0;
          const eachGraphWidthRate = Math.ceil(
            (graph.total / graphTotalSum) * 100,
          );
          const actualDomWidth =
            graphDomTotalWidth * (eachGraphWidthRate / 100);

          return (
            <BasicArrowTooltip
              key={index}
              title={graph.tooltipTitle}
              disableHoverListener={
                graph.tooltipTitle === null || graph.tooltipTitle === undefined
              }
              arrow
              placement="top"
            >
              <div
                style={{
                  width: graphTotalSum
                    ? graphDomTotalWidth
                      ? actualDomWidth < 0.5
                        ? '0.5rem'
                        : `${eachGraphWidthRate}%`
                      : `${eachGraphWidthRate}%`
                    : '100%',
                }}
                className={`pb-2`}
              >
                <div
                  className={`h-1 rounded-lg bg-my-gray-80 relative ${
                    graph.tooltipTitle ? 'cursor-pointer' : ''
                  }`}
                  onClick={() => graph.onClickEvent && graph.onClickEvent()}
                >
                  <div
                    style={{
                      width: `${
                        graphValueRate > 100 || !graphTotalSum
                          ? 100
                          : graphValueRate
                      }%`,
                      background:
                        graphValueRate === 0
                          ? 'transparent'
                          : getGraphColor(graphValueRate),
                    }}
                    className={`h-1 rounded-lg absolute top-0 left-0`}
                  ></div>
                </div>
              </div>
            </BasicArrowTooltip>
          );
        })}
      </div>
      <div className={`flex justify-between w-full`}>
        {textFormat.map((format, key) => {
          return (
            <div className="text-label-sm-600 text-my-gray-30" key={key}>
              {format === 'time' || format === 'problemCount' ? (
                <>
                  {getText(graphValueSum, format)} /{' '}
                  <span className="text-my-gray-30">
                    {graphTotalSum ? getText(graphTotalSum, format) : '-'}
                  </span>
                </>
              ) : (
                getText(Math.floor(totalWeightedValueSum), format)
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LineGraphWithText;
