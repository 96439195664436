import { SearchInputStyle } from '@styles/common/input/InputStyles';
import React, { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

const SearchInput = ({
  placeholder = '',
  width = null,
  disabled = false,
  defaultValue = null,
  hookFormRegister = null,
  onEnterKeyDown = null,
}: {
  placeholder?: string;
  width?: string;
  disabled?: boolean;
  defaultValue?: string;
  hookFormRegister?: UseFormRegisterReturn;
  onEnterKeyDown?: (value: string) => void;
}) => {
  const [value, setValue] = useState(null);
  const { onChange: onChangeForm, ...searchInputFormRegister } =
    hookFormRegister ? hookFormRegister : { onChange: null };

  return (
    <SearchInputStyle width={width}>
      <input
        defaultValue={defaultValue ? defaultValue : ''}
        type="search"
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
          onChangeForm && onChangeForm(e);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onEnterKeyDown && onEnterKeyDown(value);
        }}
        disabled={disabled}
        {...searchInputFormRegister}
      />
    </SearchInputStyle>
  );
};

export default SearchInput;
