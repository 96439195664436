import React from 'react';

const AssignmentTurnedInIcon = ({
  width = '16',
  height = '16',
  color = 'white',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M7.06667 8.83335L5.63333 7.40002C5.51111 7.2778 5.35556 7.21669 5.16667 7.21669C4.97778 7.21669 4.82222 7.2778 4.7 7.40002C4.57778 7.52224 4.51667 7.6778 4.51667 7.86669C4.51667 8.05558 4.57778 8.21113 4.7 8.33335L6.6 10.2334C6.73333 10.3667 6.88889 10.4334 7.06667 10.4334C7.24444 10.4334 7.4 10.3667 7.53333 10.2334L11.3 6.46669C11.4222 6.34446 11.4833 6.18891 11.4833 6.00002C11.4833 5.81113 11.4222 5.65558 11.3 5.53335C11.1778 5.41113 11.0222 5.35002 10.8333 5.35002C10.6444 5.35002 10.4889 5.41113 10.3667 5.53335L7.06667 8.83335ZM3.33333 14C2.96667 14 2.65278 13.8695 2.39167 13.6084C2.13056 13.3472 2 13.0334 2 12.6667V3.33335C2 2.96669 2.13056 2.6528 2.39167 2.39169C2.65278 2.13058 2.96667 2.00002 3.33333 2.00002H6.13333C6.27778 1.60002 6.51944 1.2778 6.85833 1.03335C7.19722 0.788909 7.57778 0.666687 8 0.666687C8.42222 0.666687 8.80278 0.788909 9.14167 1.03335C9.48056 1.2778 9.72222 1.60002 9.86667 2.00002H12.6667C13.0333 2.00002 13.3472 2.13058 13.6083 2.39169C13.8694 2.6528 14 2.96669 14 3.33335V12.6667C14 13.0334 13.8694 13.3472 13.6083 13.6084C13.3472 13.8695 13.0333 14 12.6667 14H3.33333ZM3.33333 12.6667H12.6667V3.33335H3.33333V12.6667ZM8 2.83335C8.14444 2.83335 8.26389 2.78613 8.35833 2.69169C8.45278 2.59724 8.5 2.4778 8.5 2.33335C8.5 2.18891 8.45278 2.06946 8.35833 1.97502C8.26389 1.88058 8.14444 1.83335 8 1.83335C7.85556 1.83335 7.73611 1.88058 7.64167 1.97502C7.54722 2.06946 7.5 2.18891 7.5 2.33335C7.5 2.4778 7.54722 2.59724 7.64167 2.69169C7.73611 2.78613 7.85556 2.83335 8 2.83335Z"
        fill={color}
      />
    </svg>
  );
};
export const FilledAssignmentTurnedInIcon = ({
  width = '16',
  height = '16',
  color = 'white',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M7.06667 8.83335L5.63333 7.40002C5.51111 7.2778 5.35556 7.21669 5.16667 7.21669C4.97778 7.21669 4.82222 7.2778 4.7 7.40002C4.57778 7.52224 4.51667 7.6778 4.51667 7.86669C4.51667 8.05558 4.57778 8.21113 4.7 8.33335L6.6 10.2334C6.73333 10.3667 6.88889 10.4334 7.06667 10.4334C7.24444 10.4334 7.4 10.3667 7.53333 10.2334L11.3 6.46669C11.4222 6.34446 11.4833 6.18891 11.4833 6.00002C11.4833 5.81113 11.4222 5.65558 11.3 5.53335C11.1778 5.41113 11.0222 5.35002 10.8333 5.35002C10.6444 5.35002 10.4889 5.41113 10.3667 5.53335L7.06667 8.83335ZM3.33333 14C2.96667 14 2.65278 13.8695 2.39167 13.6084C2.13056 13.3472 2 13.0334 2 12.6667V3.33335C2 2.96669 2.13056 2.6528 2.39167 2.39169C2.65278 2.13058 2.96667 2.00002 3.33333 2.00002H6.13333C6.27778 1.60002 6.51944 1.2778 6.85833 1.03335C7.19722 0.788909 7.57778 0.666687 8 0.666687C8.42222 0.666687 8.80278 0.788909 9.14167 1.03335C9.48056 1.2778 9.72222 1.60002 9.86667 2.00002H12.6667C13.0333 2.00002 13.3472 2.13058 13.6083 2.39169C13.8694 2.6528 14 2.96669 14 3.33335V12.6667C14 13.0334 13.8694 13.3472 13.6083 13.6084C13.3472 13.8695 13.0333 14 12.6667 14H3.33333ZM8 2.83335C8.14444 2.83335 8.26389 2.78613 8.35833 2.69169C8.45278 2.59724 8.5 2.4778 8.5 2.33335C8.5 2.18891 8.45278 2.06946 8.35833 1.97502C8.26389 1.88058 8.14444 1.83335 8 1.83335C7.85556 1.83335 7.73611 1.88058 7.64167 1.97502C7.54722 2.06946 7.5 2.18891 7.5 2.33335C7.5 2.4778 7.54722 2.59724 7.64167 2.69169C7.73611 2.78613 7.85556 2.83335 8 2.83335Z"
        fill={color}
      />
    </svg>
  );
};

export default AssignmentTurnedInIcon;
