import RefreshIcon from '@asset/svg/RefreshIcon';
import { NotifyUpdateToastStyle } from '@styles/common/toastStyle';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';

const NotifyUpdateToast = () => {
  const { t, ready } = useTranslation(['toast']);
  const router = useRouter();

  return (
    <NotifyUpdateToastStyle onClick={() => router.reload()}>
      {ready ? t('toast:noticeOfUpdate') : ''}
      <RefreshIcon />
    </NotifyUpdateToastStyle>
  );
};

export default NotifyUpdateToast;
