import styled from 'styled-components';
import tw from 'twin.macro';
import { ToastContainer } from 'react-toastify';

export const CustomToastContainer = styled(ToastContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;

  .Toastify__toast {
    width: fit-content;
    height: 3rem;
    padding: 0.75rem 1rem;

    background: var(--gray-100);
    border-radius: 0.5rem;
    box-shadow: 0px 4px 16px 0px rgba(25, 32, 41, 0.16);

    .Toastify__toast-body {
      ${tw`p-0 m-0`}

      >div:nth-child(2) {
        ${tw`text-body-md-500 text-my-gray-30 whitespace-nowrap`}

        .snack-bar {
          ${tw`flex items-center gap-4`}
          > div:first-child {
            ${tw`text-body-md-500 text-my-gray-30 whitespace-nowrap`}
          }
          > div:nth-child(2) {
            > div {
              ${tw`cursor-pointer text-body-sm-700 text-my-blue-60 whitespace-nowrap`}
            }
          }
        }
      }
    }

    .Toastify__close-button {
      display: none;
    }
  }
`;

export const NotifyUpdateToastStyle = styled.button`
  @keyframes move {
    0% {
      transform: translate(-50%, -200px);
      display: none;
    }
    85% {
      transform: translate(-50%, 10px);
      display: flex;
    }
    95% {
      transform: translate(-50%, 5px);
      display: flex;
    }
    100% {
      transform: translate(-50%, 0);
      display: flex;
    }
  }
  & {
    ${tw`py-3 px-6 border border-solid border-my-gray-20 rounded-[1.25rem] bg-my-gray-100 w-fit text-label-sm-600 text-my-gray-10 hover:bg-my-gray-90 active:bg-my-gray-90 cursor-pointer flex items-center gap-2 fixed z-[100]`}

    box-shadow: 0px 4px 16px 0px rgba(25, 32, 41, 0.16);
    top: calc(var(--height-main-nav) + 0.5rem);
    left: 50%;
    transform: translateX(-50%);

    animation-name: move;
    animation-duration: 0.5s;
  }
`;
