import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { DateTime } from 'luxon';
dayjs.extend(isoWeek);

export const WEEKDAY_ENG = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];
export const TRANSLATE_WEEKDAY_2_WORD_LIST = [
  'common:unit.weekDay2.SUNDAY',
  'common:unit.weekDay2.MONDAY',
  'common:unit.weekDay2.TUESDAY',
  'common:unit.weekDay2.WEDNESDAY',
  'common:unit.weekDay2.THURSDAY',
  'common:unit.weekDay2.FRIDAY',
  'common:unit.weekDay2.SATURDAY',
];
export const WEEKDAY_NUM = [0, 1, 2, 3, 4, 5, 6];

export const getStartOfISOWeek = (day: Dayjs) => {
  if (day.utcOffset() === 0) {
    return day.utc().startOf('day').isoWeekday(1);
  }

  return day.startOf('day').isoWeekday(1);
};
export const getEndOfISOWeek = (day: Dayjs) => {
  if (day.utcOffset() === 0) {
    return day.utc().startOf('day').isoWeekday(7);
  }
  return day.startOf('day').isoWeekday(7);
};

export const convertTimeStampToTimeZoneDate = (dateTimeStamp: number): Date => {
  const localeTime: Date = new Date();
  const localeTimeZoneOffsetInHours: number =
    (localeTime.getTimezoneOffset() * -1) / 60;
  const timezoneOffsetInHours: number =
    DateTime.fromSeconds(dateTimeStamp).offset / 60;
  const resultDate: Date = DateTime.fromSeconds(dateTimeStamp)
    .minus({ hours: localeTimeZoneOffsetInHours })
    .plus({ hours: timezoneOffsetInHours })
    .toJSDate();
  return resultDate;
};

export const createNewDateWithTimeZone = (
  date: Date,
  timezone: string,
): DateTime => {
  const newYear = date.getFullYear();
  const newMonth = date.getMonth() + 1;
  const newDate = date.getDate();
  const resultDate = DateTime.fromObject(
    {
      year: newYear,
      month: newMonth,
      day: newDate,
    },
    {
      zone: timezone,
    },
  );
  return resultDate;
};
