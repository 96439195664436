export const getBasicButtonColorAsset = (color, type) => {
  const colorAsset = {
    'green-Primary': {
      backgroundColor: 'var(--green-50)',
      hoverBackgroundColor: 'var(--green-40)',
      activeBackgroundColor: 'var(--green-30)',
      borderColor: 'transparent',
      contentColor: 'var(--gray-100)',
      disabledBackgroundColor: 'var(--green-90)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--gray-100)',
    },
    'green-Secondary': {
      backgroundColor: 'var(--green-95)',
      hoverBackgroundColor: 'var(--green-90)',
      activeBackgroundColor: 'var(--green-80)',
      borderColor: 'transparent',
      contentColor: 'var(--green-50)',
      disabledBackgroundColor: 'var(--green-95)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--green-80)',
    },
    'green-Tertiary': {
      backgroundColor: 'var(--gray-100)',
      hoverBackgroundColor: 'var(--green-98)',
      activeBackgroundColor: 'var(--green-95)',
      borderColor: 'var(--green-50)',
      contentColor: 'var(--green-50)',
      disabledBackgroundColor: 'var(--gray-100)',
      disabledBorderColor: 'var(--green-80)',
      disabledContentColor: 'var(--green-80)',
    },
    'red-Primary': {
      backgroundColor: 'var(--red-50)',
      hoverBackgroundColor: 'var(--red-40)',
      activeBackgroundColor: 'var(--red-30)',
      borderColor: 'transparent',
      contentColor: 'var(--gray-100)',
      disabledBackgroundColor: 'var(--red-90)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--gray-100)',
    },
    'red-Secondary': {
      backgroundColor: 'var(--red-95)',
      hoverBackgroundColor: 'var(--red-90)',
      activeBackgroundColor: 'var(--red-80)',
      borderColor: 'transparent',
      contentColor: 'var(--red-50)',
      disabledBackgroundColor: 'var(--red-95)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--red-80)',
    },
    'red-Tertiary': {
      backgroundColor: 'var(--gray-100)',
      hoverBackgroundColor: 'var(--red-98)',
      activeBackgroundColor: 'var(--red-95)',
      borderColor: 'var(--red-50)',
      contentColor: 'var(--red-50)',
      disabledBackgroundColor: 'var(--gray-100)',
      disabledBorderColor: 'var(--red-80)',
      disabledContentColor: 'var(--red-80)',
    },
    'blue-Primary': {
      backgroundColor: 'var(--blue-50)',
      hoverBackgroundColor: 'var(--blue-40)',
      activeBackgroundColor: 'var(--blue-30)',
      borderColor: 'transparent',
      contentColor: 'var(--gray-100)',
      disabledBackgroundColor: 'var(--blue-90)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--gray-100)',
    },
    'blue-Secondary': {
      backgroundColor: 'var(--blue-95)',
      hoverBackgroundColor: 'var(--blue-90)',
      activeBackgroundColor: 'var(--blue-80)',
      borderColor: 'transparent',
      contentColor: 'var(--blue-50)',
      disabledBackgroundColor: 'var(--blue-95)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--blue-80)',
    },
    'blue-Tertiary': {
      backgroundColor: 'var(--gray-100)',
      hoverBackgroundColor: 'var(--blue-98)',
      activeBackgroundColor: 'var(--blue-95)',
      borderColor: 'var(--blue-50)',
      contentColor: 'var(--blue-50)',
      disabledBackgroundColor: 'var(--gray-100)',
      disabledBorderColor: 'var(--blue-80)',
      disabledContentColor: 'var(--blue-80)',
    },
    'orange-Primary': {
      backgroundColor: 'var(--orange-50)',
      hoverBackgroundColor: 'var(--orange-40)',
      activeBackgroundColor: 'var(--orange-30)',
      borderColor: 'transparent',
      contentColor: 'var(--gray-100)',
      disabledBackgroundColor: 'var(--orange-90)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--gray-100)',
    },
    'orange-Secondary': {
      backgroundColor: 'var(--orange-95)',
      hoverBackgroundColor: 'var(--orange-90)',
      activeBackgroundColor: 'var(--orange-80)',
      borderColor: 'transparent',
      contentColor: 'var(--orange-50)',
      disabledBackgroundColor: 'var(--orange-95)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--orange-80)',
    },
    'orange-Tertiary': {
      backgroundColor: 'var(--gray-100)',
      hoverBackgroundColor: 'var(--orange-98)',
      activeBackgroundColor: 'var(--orange-95)',
      borderColor: 'var(--orange-50)',
      contentColor: 'var(--orange-50)',
      disabledBackgroundColor: 'var(--gray-100)',
      disabledBorderColor: 'var(--orange-80)',
      disabledContentColor: 'var(--orange-80)',
    },
    'purple-Primary': {
      backgroundColor: 'var(--purple-50)',
      hoverBackgroundColor: 'var(--purple-40)',
      activeBackgroundColor: 'var(--purple-30)',
      borderColor: 'transparent',
      contentColor: 'var(--gray-100)',
      disabledBackgroundColor: 'var(--purple-90)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--gray-100)',
    },
    'purple-Secondary': {
      backgroundColor: 'var(--purple-95)',
      hoverBackgroundColor: 'var(--purple-90)',
      activeBackgroundColor: 'var(--purple-80)',
      borderColor: 'transparent',
      contentColor: 'var(--purple-50)',
      disabledBackgroundColor: 'var(--purple-95)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--purple-80)',
    },
    'purple-Tertiary': {
      backgroundColor: 'var(--gray-100)',
      hoverBackgroundColor: 'var(--purple-98)',
      activeBackgroundColor: 'var(--purple-95)',
      borderColor: 'var(--purple-50)',
      contentColor: 'var(--purple-50)',
      disabledBackgroundColor: 'var(--gray-100)',
      disabledBorderColor: 'var(--purple-80)',
      disabledContentColor: 'var(--purple-80)',
    },
    'yellow-Primary': {
      backgroundColor: 'var(--yellow-50)',
      hoverBackgroundColor: 'var(--yellow-40)',
      activeBackgroundColor: 'var(--yellow-30)',
      borderColor: 'transparent',
      contentColor: 'var(--gray-100)',
      disabledBackgroundColor: 'var(--yellow-90)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--gray-100)',
    },
    'yellow-Secondary': {
      backgroundColor: 'var(--yellow-95)',
      hoverBackgroundColor: 'var(--yellow-90)',
      activeBackgroundColor: 'var(--yellow-80)',
      borderColor: 'transparent',
      contentColor: 'var(--yellow-40)',
      disabledBackgroundColor: 'var(--yellow-95)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--yellow-80)',
    },
    'yellow-Tertiary': {
      backgroundColor: 'var(--gray-100)',
      hoverBackgroundColor: 'var(--yellow-98)',
      activeBackgroundColor: 'var(--yellow-95)',
      borderColor: 'var(--yellow-50)',
      contentColor: 'var(--yellow-50)',
      disabledBackgroundColor: 'var(--gray-100)',
      disabledBorderColor: 'var(--yellow-80)',
      disabledContentColor: 'var(--yellow-80)',
    },
    'gray-Primary': {
      backgroundColor: 'var(--gray-10)',
      hoverBackgroundColor: 'var(--gray-20)',
      activeBackgroundColor: 'var(--gray-30)',
      borderColor: 'transparent',
      contentColor: 'var(--gray-100)',
      disabledBackgroundColor: 'var(--gray-80)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--gray-100)',
    },
    'gray-Secondary': {
      backgroundColor: 'var(--gray-90)',
      hoverBackgroundColor: 'var(--gray-80)',
      activeBackgroundColor: 'var(--gray-70)',
      borderColor: 'transparent',
      contentColor: 'var(--gray-30)',
      disabledBackgroundColor: 'var(--gray-95)',
      disabledBorderColor: 'transparent',
      disabledContentColor: 'var(--gray-70)',
    },
    'gray-Tertiary': {
      backgroundColor: 'var(--gray-100)',
      hoverBackgroundColor: 'var(--gray-95)',
      activeBackgroundColor: 'var(--gray-90)',
      borderColor: 'var(--gray-40)',
      contentColor: 'var(--gray-30)',
      disabledBackgroundColor: 'var(--gray-100)',
      disabledBorderColor: 'var(--gray-80)',
      disabledContentColor: 'var(--gray-70)',
    },
  };

  return colorAsset[`${color}-${type}`];
};

export const basicButtonSizeAssetGroup = {
  Xs: {
    height: '2rem',
    paddingX: '1rem',
    paddingY: '0.5rem',
    iconPadding: '0.625rem',
    fontSize: '0.75rem',
    fontWeight: '700',
    lineHeight: '0.875rem',
  },
  S: {
    height: '2.5rem',
    paddingX: '1rem',
    paddingY: '0.75rem',
    iconPadding: '0.625rem',
    fontSize: '0.875rem',
    fontWeight: '700',
    lineHeight: '1rem',
  },
  M: {
    height: '3rem',
    paddingX: '1.5rem',
    paddingY: '1rem',
    iconPadding: '1rem',
    fontSize: '0.875rem',
    fontWeight: '700',
    lineHeight: '1rem',
  },
  L: {
    height: '3.5rem',
    paddingX: '1.5rem',
    paddingY: '1rem',
    iconPadding: '1rem',
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '1.5rem',
  },
};

export const buttonIconSizeAssetGroup = {
  Xs: {
    width: '1.25rem',
    height: '1.25rem',
    pxWidth: 20,
    pxHeight: 20,
  },
  S: {
    width: '1.25rem',
    height: '1.25rem',
    pxWidth: 20,
    pxHeight: 20,
  },
  M: {
    width: '1.25rem',
    height: '1.25rem',
    pxWidth: 20,
    pxHeight: 20,
  },
  L: {
    width: '1.25rem',
    height: '1.25rem',
    pxWidth: 20,
    pxHeight: 20,
  },
};
