import React from 'react';

const TrophyIcon = ({ width = '16', height = '16', color = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M8 7.33333C8.36667 7.33333 8.68056 7.20278 8.94167 6.94167C9.20278 6.68056 9.33333 6.36667 9.33333 6C9.33333 5.63333 9.20278 5.31944 8.94167 5.05833C8.68056 4.79722 8.36667 4.66667 8 4.66667C7.63333 4.66667 7.31944 4.79722 7.05833 5.05833C6.79722 5.31944 6.66667 5.63333 6.66667 6C6.66667 6.36667 6.79722 6.68056 7.05833 6.94167C7.31944 7.20278 7.63333 7.33333 8 7.33333ZM7.33333 12.6667V10.6C6.78889 10.4778 6.30278 10.2472 5.875 9.90833C5.44722 9.56944 5.13333 9.14444 4.93333 8.63333C4.1 8.53333 3.40278 8.16944 2.84167 7.54167C2.28056 6.91389 2 6.17778 2 5.33333V4.66667C2 4.3 2.13056 3.98611 2.39167 3.725C2.65278 3.46389 2.96667 3.33333 3.33333 3.33333H4.66667C4.66667 2.96667 4.79722 2.65278 5.05833 2.39167C5.31944 2.13056 5.63333 2 6 2H10C10.3667 2 10.6806 2.13056 10.9417 2.39167C11.2028 2.65278 11.3333 2.96667 11.3333 3.33333H12.6667C13.0333 3.33333 13.3472 3.46389 13.6083 3.725C13.8694 3.98611 14 4.3 14 4.66667V5.33333C14 6.17778 13.7194 6.91389 13.1583 7.54167C12.5972 8.16944 11.9 8.53333 11.0667 8.63333C10.8667 9.14444 10.5528 9.56944 10.125 9.90833C9.69722 10.2472 9.21111 10.4778 8.66667 10.6V12.6667H10.6667C10.8556 12.6667 11.0139 12.7306 11.1417 12.8583C11.2694 12.9861 11.3333 13.1444 11.3333 13.3333C11.3333 13.5222 11.2694 13.6806 11.1417 13.8083C11.0139 13.9361 10.8556 14 10.6667 14H5.33333C5.14444 14 4.98611 13.9361 4.85833 13.8083C4.73056 13.6806 4.66667 13.5222 4.66667 13.3333C4.66667 13.1444 4.73056 12.9861 4.85833 12.8583C4.98611 12.7306 5.14444 12.6667 5.33333 12.6667H7.33333ZM4.66667 7.2V4.66667H3.33333V5.33333C3.33333 5.75556 3.45556 6.13611 3.7 6.475C3.94444 6.81389 4.26667 7.05556 4.66667 7.2ZM8 9.33333C8.55556 9.33333 9.02778 9.13889 9.41667 8.75C9.80556 8.36111 10 7.88889 10 7.33333V3.33333H6V7.33333C6 7.88889 6.19444 8.36111 6.58333 8.75C6.97222 9.13889 7.44444 9.33333 8 9.33333ZM11.3333 7.2C11.7333 7.05556 12.0556 6.81389 12.3 6.475C12.5444 6.13611 12.6667 5.75556 12.6667 5.33333V4.66667H11.3333V7.2Z"
        fill={color}
      />
    </svg>
  );
};
export const FilledTrophyIcon = ({
  width = '16',
  height = '16',
  color = 'white',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M8 7.33333C8.36667 7.33333 8.68056 7.20278 8.94167 6.94167C9.20278 6.68056 9.33333 6.36667 9.33333 6C9.33333 5.63333 9.20278 5.31944 8.94167 5.05833C8.68056 4.79722 8.36667 4.66667 8 4.66667C7.63333 4.66667 7.31944 4.79722 7.05833 5.05833C6.79722 5.31944 6.66667 5.63333 6.66667 6C6.66667 6.36667 6.79722 6.68056 7.05833 6.94167C7.31944 7.20278 7.63333 7.33333 8 7.33333ZM4.66667 7.2V4.66667H3.33333V5.33333C3.33333 5.75556 3.45556 6.13611 3.7 6.475C3.94444 6.81389 4.26667 7.05556 4.66667 7.2ZM11.3333 7.2C11.7333 7.05556 12.0556 6.81389 12.3 6.475C12.5444 6.13611 12.6667 5.75556 12.6667 5.33333V4.66667H11.3333V7.2ZM7.33333 12.6667V10.6C6.78889 10.4778 6.30278 10.2472 5.875 9.90833C5.44722 9.56944 5.13333 9.14444 4.93333 8.63333C4.1 8.53333 3.40278 8.16944 2.84167 7.54167C2.28056 6.91389 2 6.17778 2 5.33333V4.66667C2 4.3 2.13056 3.98611 2.39167 3.725C2.65278 3.46389 2.96667 3.33333 3.33333 3.33333H4.66667C4.66667 2.96667 4.79722 2.65278 5.05833 2.39167C5.31944 2.13056 5.63333 2 6 2H10C10.3667 2 10.6806 2.13056 10.9417 2.39167C11.2028 2.65278 11.3333 2.96667 11.3333 3.33333H12.6667C13.0333 3.33333 13.3472 3.46389 13.6083 3.725C13.8694 3.98611 14 4.3 14 4.66667V5.33333C14 6.17778 13.7194 6.91389 13.1583 7.54167C12.5972 8.16944 11.9 8.53333 11.0667 8.63333C10.8667 9.14444 10.5528 9.56944 10.125 9.90833C9.69722 10.2472 9.21111 10.4778 8.66667 10.6V12.6667H10.6667C10.8556 12.6667 11.0139 12.7306 11.1417 12.8583C11.2694 12.9861 11.3333 13.1444 11.3333 13.3333C11.3333 13.5222 11.2694 13.6806 11.1417 13.8083C11.0139 13.9361 10.8556 14 10.6667 14H5.33333C5.14444 14 4.98611 13.9361 4.85833 13.8083C4.73056 13.6806 4.66667 13.5222 4.66667 13.3333C4.66667 13.1444 4.73056 12.9861 4.85833 12.8583C4.98611 12.7306 5.14444 12.6667 5.33333 12.6667H7.33333Z"
        fill={color}
      />
    </svg>
  );
};

export default TrophyIcon;
