import {
  basicButtonSizeAssetGroup,
  getBasicButtonColorAsset,
} from '@asset/design/buttonAsset';
import { BasicButtonProps } from '@asset/type/common/buttonType';
import { BasicButtonStyle } from '@styles/common/button/buttonStyles';
import React from 'react';

const BasicButton = ({
  color,
  assetType,
  size,
  content,
  onClickEvent = null,
  isActive = true,
  buttonType = 'button',
  width = 'fit-content',
}: BasicButtonProps) => {
  const colorAsset = getBasicButtonColorAsset(color, assetType);
  const sizeAsset = basicButtonSizeAssetGroup[size];
  return (
    <>
      <BasicButtonStyle
        sizeAsset={sizeAsset}
        colorAsset={colorAsset}
        isActive={isActive}
        onClick={(e) => {
          onClickEvent && isActive && onClickEvent(e);
        }}
        type={buttonType}
        disabled={!isActive}
        width={width}
      >
        {content}
      </BasicButtonStyle>
    </>
  );
};

export default BasicButton;
