import React from 'react';

const GridViewIcon = ({ width = '16', height = '16', color = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={color}
    >
      <path d="M3.33333 7.33333C2.96667 7.33333 2.65278 7.20278 2.39167 6.94167C2.13056 6.68056 2 6.36667 2 6V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H6C6.36667 2 6.68056 2.13056 6.94167 2.39167C7.20278 2.65278 7.33333 2.96667 7.33333 3.33333V6C7.33333 6.36667 7.20278 6.68056 6.94167 6.94167C6.68056 7.20278 6.36667 7.33333 6 7.33333H3.33333ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V10C2 9.63333 2.13056 9.31945 2.39167 9.05833C2.65278 8.79722 2.96667 8.66667 3.33333 8.66667H6C6.36667 8.66667 6.68056 8.79722 6.94167 9.05833C7.20278 9.31945 7.33333 9.63333 7.33333 10V12.6667C7.33333 13.0333 7.20278 13.3472 6.94167 13.6083C6.68056 13.8694 6.36667 14 6 14H3.33333ZM10 7.33333C9.63333 7.33333 9.31945 7.20278 9.05833 6.94167C8.79722 6.68056 8.66667 6.36667 8.66667 6V3.33333C8.66667 2.96667 8.79722 2.65278 9.05833 2.39167C9.31945 2.13056 9.63333 2 10 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V6C14 6.36667 13.8694 6.68056 13.6083 6.94167C13.3472 7.20278 13.0333 7.33333 12.6667 7.33333H10ZM10 14C9.63333 14 9.31945 13.8694 9.05833 13.6083C8.79722 13.3472 8.66667 13.0333 8.66667 12.6667V10C8.66667 9.63333 8.79722 9.31945 9.05833 9.05833C9.31945 8.79722 9.63333 8.66667 10 8.66667H12.6667C13.0333 8.66667 13.3472 8.79722 13.6083 9.05833C13.8694 9.31945 14 9.63333 14 10V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H10ZM3.33333 6H6V3.33333H3.33333V6ZM10 6H12.6667V3.33333H10V6ZM10 12.6667H12.6667V10H10V12.6667ZM3.33333 12.6667H6V10H3.33333V12.6667Z" />
    </svg>
  );
};
export const FilledGridViewIcon = ({
  width = '16',
  height = '16',
  color = 'white',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M3.33333 7.33333C2.96667 7.33333 2.65278 7.20278 2.39167 6.94167C2.13056 6.68056 2 6.36667 2 6V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H6C6.36667 2 6.68056 2.13056 6.94167 2.39167C7.20278 2.65278 7.33333 2.96667 7.33333 3.33333V6C7.33333 6.36667 7.20278 6.68056 6.94167 6.94167C6.68056 7.20278 6.36667 7.33333 6 7.33333H3.33333ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V10C2 9.63333 2.13056 9.31945 2.39167 9.05833C2.65278 8.79722 2.96667 8.66667 3.33333 8.66667H6C6.36667 8.66667 6.68056 8.79722 6.94167 9.05833C7.20278 9.31945 7.33333 9.63333 7.33333 10V12.6667C7.33333 13.0333 7.20278 13.3472 6.94167 13.6083C6.68056 13.8694 6.36667 14 6 14H3.33333ZM10 7.33333C9.63333 7.33333 9.31945 7.20278 9.05833 6.94167C8.79722 6.68056 8.66667 6.36667 8.66667 6V3.33333C8.66667 2.96667 8.79722 2.65278 9.05833 2.39167C9.31945 2.13056 9.63333 2 10 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V6C14 6.36667 13.8694 6.68056 13.6083 6.94167C13.3472 7.20278 13.0333 7.33333 12.6667 7.33333H10ZM10 14C9.63333 14 9.31945 13.8694 9.05833 13.6083C8.79722 13.3472 8.66667 13.0333 8.66667 12.6667V10C8.66667 9.63333 8.79722 9.31945 9.05833 9.05833C9.31945 8.79722 9.63333 8.66667 10 8.66667H12.6667C13.0333 8.66667 13.3472 8.79722 13.6083 9.05833C13.8694 9.31945 14 9.63333 14 10V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H10Z"
        fill={color}
      />
    </svg>
  );
};

export default GridViewIcon;
