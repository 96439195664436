import React from 'react';

const ChevronLeft = ({
  width = '16',
  height = '16',
  color = 'var(--blue-50)',
  onClick = null,
  classes = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      className={`${onClick ? 'cursor-pointer' : ''} ${classes}`}
    >
      <path
        d="M10.325 12L14.225 15.9C14.4083 16.0833 14.5 16.3167 14.5 16.6C14.5 16.8833 14.4083 17.1167 14.225 17.3C14.0417 17.4833 13.8083 17.575 13.525 17.575C13.2417 17.575 13.0083 17.4833 12.825 17.3L8.22501 12.7C8.12501 12.6 8.05418 12.4917 8.01251 12.375C7.97085 12.2583 7.95001 12.1333 7.95001 12C7.95001 11.8667 7.97085 11.7417 8.01251 11.625C8.05418 11.5083 8.12501 11.4 8.22501 11.3L12.825 6.7C13.0083 6.51667 13.2417 6.425 13.525 6.425C13.8083 6.425 14.0417 6.51667 14.225 6.7C14.4083 6.88334 14.5 7.11667 14.5 7.4C14.5 7.68334 14.4083 7.91667 14.225 8.1L10.325 12Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronLeft;
