import React, { useCallback } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';
import {
  SideBarListItemStyle,
  SideBarListStyle,
} from '@styles/common/list/sideBarListStyles';
import { UrlObject } from 'url';

interface Props {
  list: {
    title: string;
    link: string | UrlObject;
    keyword: string[];
    icon?: JSX.Element;
    filledIcon?: JSX.Element;
  }[];
  isRequiredFold?: boolean;
  headerItem?: JSX.Element;
}

const SideBarList = ({ list, headerItem = null }: Props) => {
  const router = useRouter();
  const scrollRef = useCallback((ref) => {
    if (ref) {
      ref.scrollIntoViewIfNeeded();
    }
  }, []);

  const logManageStudentClickEvent = (btnTxt, redirectUrl) => {
    const eventProperties = {
      buttonText: btnTxt,
      redirectUrl: redirectUrl,
    };
    amplitude.track(`click_teacher_manage_student_side_bar`, eventProperties);
  };

  const isPicked = useCallback(
    (keywordList) => {
      let isPicked = false;
      for (let i = 0; i < keywordList?.length; i++) {
        const keyword = keywordList[i];
        if (router.asPath.startsWith(keyword)) {
          isPicked = true;
          break;
        }
      }
      return isPicked;
    },
    [router],
  );

  return (
    <SideBarListStyle>
      <div>
        {headerItem && headerItem}

        <ul>
          {list.map((data, key) => (
            <Link key={key} href={data.link}>
              <a>
                <SideBarListItemStyle
                  className={`${isPicked(data.keyword) ? 'picked' : ''}`}
                  ref={scrollRef}
                  onClick={() =>
                    logManageStudentClickEvent(data.title, data.link)
                  }
                >
                  {data.icon ? (
                    <div>
                      {isPicked(data.keyword) && data.filledIcon
                        ? data.filledIcon
                        : data.icon}
                    </div>
                  ) : (
                    <></>
                  )}
                  <span>{data.title}</span>
                </SideBarListItemStyle>
              </a>
            </Link>
          ))}
        </ul>
      </div>
    </SideBarListStyle>
  );
};

export default SideBarList;
