import styled from 'styled-components';
import tw from 'twin.macro';

export const BasicMenu = styled.ul`
  & {
    ${tw`absolute w-fit bg-my-gray-100 border border-solid border-my-gray-80 rounded-xl z-[100] py-1`}
    top: ${(props) =>
      props.customStyles?.top ? props.customStyles?.top : '50%'};
    right: ${(props) =>
      props.customStyles?.right ? props.customStyles?.right : '0'};
    transform: ${(props) =>
      props.customStyles?.transform ? props.customStyles?.transform : ''};

    > span {
      ${tw`pb-2 pl-4 text-my-gray-50 text-label-sm-600`}
    }
    > li {
      ${tw`py-[0.62rem] px-4 text-body-md-500 cursor-pointer`}
      &.selected {
        ${tw`text-my-blue-50`}
      }
      &:hover {
        background: linear-gradient(
            0deg,
            var(--Hover, color(display-p3 0.098 0.1255 0.1608 / 0.08)) 0%,
            var(--Hover, color(display-p3 0.098 0.1255 0.1608 / 0.08)) 100%
          ),
          var(--colors-gray-gray-100, color(display-p3 1 1 1));
      }
    }
  }
`;

export const BasicMenuItemContent = styled.div`
  & {
    ${tw`flex gap-2 w-[10rem] items-center text-body-md-500`}
  }
`;

export const SelectStudentDropdownCustomControl = styled.div`
  & {
    ${tw`relative flex items-center gap-2 p-2`}

    div[class*='ValueContainer'] {
      input {
        width: fit-content !important;
      }
    }

    button.filter-button {
      ${tw`flex items-center justify-center p-2 rounded-lg hover:bg-my-gray-90 active:bg-my-gray-90`}
    }
  }
`;
