import { IconProps } from '@asset/type/icon';
import React from 'react';

export const ArrowLeft = ({
  width = '30',
  height = '30',
  color = 'var(--gray-10)',
  onClick = null,
  className = '',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={() => onClick && onClick()}
      className={className}
    >
      <path
        d="M10.15 16.3C10.0167 16.3 9.90002 16.2542 9.80002 16.1625C9.70002 16.0708 9.65002 15.95 9.65002 15.8V8.2C9.65002 8.05 9.70002 7.92916 9.80002 7.8375C9.90002 7.74583 10.0167 7.7 10.15 7.7C10.1834 7.7 10.3 7.75 10.5 7.85L14.125 11.475C14.2084 11.5583 14.2667 11.6417 14.3 11.725C14.3334 11.8083 14.35 11.9 14.35 12C14.35 12.1 14.3334 12.1917 14.3 12.275C14.2667 12.3583 14.2084 12.4417 14.125 12.525L10.5 16.15C10.45 16.2 10.3959 16.2375 10.3375 16.2625C10.2792 16.2875 10.2167 16.3 10.15 16.3Z"
        fill={color}
      />
    </svg>
  );
};

export const ArrowRight = ({
  width = '24',
  height = '24',
  color = 'var(--gray-10)',
  onClick = null,
  className = '',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      onClick={() => onClick && onClick()}
      className={className}
    >
      <path
        d="M10.1499 16.7992C10.0166 16.7992 9.8999 16.7534 9.7999 16.6617C9.6999 16.5701 9.6499 16.4492 9.6499 16.2992V8.69922C9.6499 8.54922 9.6999 8.42839 9.7999 8.33672C9.8999 8.24505 10.0166 8.19922 10.1499 8.19922C10.1832 8.19922 10.2999 8.24922 10.4999 8.34922L14.1249 11.9742C14.2082 12.0576 14.2666 12.1409 14.2999 12.2242C14.3332 12.3076 14.3499 12.3992 14.3499 12.4992C14.3499 12.5992 14.3332 12.6909 14.2999 12.7742C14.2666 12.8576 14.2082 12.9409 14.1249 13.0242L10.4999 16.6492C10.4499 16.6992 10.3957 16.7367 10.3374 16.7617C10.2791 16.7867 10.2166 16.7992 10.1499 16.7992Z"
        fill={color}
      />
    </svg>
  );
};

export const ArrowUp = ({
  width = '24',
  height = '24',
  color = 'var(--gray-10)',
  onClick = null,
  className = '',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={() => onClick && onClick()}
      className={className}
    >
      <path
        d="M7.7002 13.85C7.7002 13.9834 7.74603 14.1 7.8377 14.2C7.92936 14.3 8.0502 14.35 8.2002 14.35H15.8002C15.9502 14.35 16.071 14.3 16.1627 14.2C16.2544 14.1 16.3002 13.9834 16.3002 13.85C16.3002 13.8167 16.2502 13.7 16.1502 13.5L12.5252 9.87502C12.4419 9.79169 12.3585 9.73336 12.2752 9.70002C12.1919 9.66669 12.1002 9.65002 12.0002 9.65002C11.9002 9.65002 11.8085 9.66669 11.7252 9.70002C11.6419 9.73336 11.5585 9.79169 11.4752 9.87502L7.8502 13.5C7.8002 13.55 7.7627 13.6042 7.7377 13.6625C7.7127 13.7209 7.7002 13.7834 7.7002 13.85Z"
        fill={color}
      />
    </svg>
  );
};

export const ArrowDown = ({
  width = '24',
  height = '24',
  color = 'var(--gray-10)',
  onClick = null,
  className = '',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={() => onClick && onClick()}
      className={className}
    >
      <path
        d="M7.7002 10.15C7.7002 10.0167 7.74603 9.90002 7.8377 9.80002C7.92936 9.70002 8.0502 9.65002 8.2002 9.65002L15.8002 9.65002C15.9502 9.65002 16.071 9.70002 16.1627 9.80002C16.2544 9.90002 16.3002 10.0167 16.3002 10.15C16.3002 10.1834 16.2502 10.3 16.1502 10.5L12.5252 14.125C12.4419 14.2084 12.3585 14.2667 12.2752 14.3C12.1919 14.3334 12.1002 14.35 12.0002 14.35C11.9002 14.35 11.8085 14.3334 11.7252 14.3C11.6419 14.2667 11.5585 14.2084 11.4752 14.125L7.8502 10.5C7.8002 10.45 7.7627 10.3959 7.7377 10.3375C7.7127 10.2792 7.7002 10.2167 7.7002 10.15Z"
        fill={color}
      />
    </svg>
  );
};

export const SmallLeftArrowIcon = ({
  width = '16',
  height = '16',
  color = 'var(--gray-30)',
  onClick = null,
  className = '',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      onClick={() => onClick && onClick()}
      className={`${className} ${onClick ? 'cursor-pointer' : ''}`}
    >
      <path
        d="M8.99977 10.7666L6.58311 8.34997C6.52755 8.29441 6.48866 8.23886 6.46644 8.1833C6.44422 8.12775 6.43311 8.06663 6.43311 7.99997C6.43311 7.9333 6.44422 7.87219 6.46644 7.81663C6.48866 7.76108 6.52755 7.70552 6.58311 7.64997L8.99977 5.2333C9.03311 5.19997 9.06922 5.17497 9.10811 5.1583C9.14699 5.14163 9.18866 5.1333 9.23311 5.1333C9.32199 5.1333 9.39977 5.16386 9.46644 5.22497C9.53311 5.28608 9.56644 5.36663 9.56644 5.46663V10.5333C9.56644 10.6333 9.53311 10.7139 9.46644 10.775C9.39977 10.8361 9.32199 10.8666 9.23311 10.8666C9.21088 10.8666 9.13311 10.8333 8.99977 10.7666Z"
        fill={color}
      />
    </svg>
  );
};

export const SmallRightArrowIcon = ({
  width = '16',
  height = '16',
  color = 'var(--gray-30)',
  onClick = null,
  className = '',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      onClick={() => onClick && onClick()}
      className={`${className} ${onClick ? 'cursor-pointer' : ''}`}
    >
      <path
        d="M6.76644 10.8666C6.67755 10.8666 6.59977 10.8361 6.53311 10.775C6.46644 10.7139 6.43311 10.6333 6.43311 10.5333V5.46663C6.43311 5.36663 6.46644 5.28608 6.53311 5.22497C6.59977 5.16386 6.67755 5.1333 6.76644 5.1333C6.78866 5.1333 6.86644 5.16663 6.99977 5.2333L9.41644 7.64997C9.47199 7.70552 9.51088 7.76108 9.53311 7.81663C9.55533 7.87219 9.56644 7.9333 9.56644 7.99997C9.56644 8.06663 9.55533 8.12775 9.53311 8.1833C9.51088 8.23886 9.47199 8.29441 9.41644 8.34997L6.99977 10.7666C6.96644 10.8 6.93033 10.825 6.89144 10.8416C6.85255 10.8583 6.81088 10.8666 6.76644 10.8666Z"
        fill={color}
      />
    </svg>
  );
};
