import React from 'react';
import LineGraphWithText from '@components/common/graph/LineGraphWithText';

const MyClassStudyTimeStatusCell = ({ studyTimeData }) => {
  const graphData = [
    {
      id: studyTimeData?.id,
      total: studyTimeData ? studyTimeData.goalTime : null,
      value: studyTimeData ? studyTimeData.elapsedTime : null,
      onClickEvent: null,
    },
  ];
  return (
    <LineGraphWithText
      textFormat={['', 'time']}
      graphData={graphData}
      graphColorCriteriaTable={{
        100: 'var(--green-70)',
        1: 'var(--orange-70)',
        0: 'var(--gray-80)',
      }}
      graphDomTotalWidth={30}
    ></LineGraphWithText>
  );
};

export default MyClassStudyTimeStatusCell;
