import { UrlObject } from 'url';

export const ACCOUNT_PAGE_URL_PREFIX = '/account/';

export const getParentAuthenticationPageUrlObject = (query: {
  redirectUrl: string;
}): UrlObject => {
  const urlString = `${ACCOUNT_PAGE_URL_PREFIX}parents/`;
  return { pathname: urlString, query: query };
};
export const getPhoneAuthenticationPageUrlObject = (query: {
  redirectUrl: string;
}): UrlObject => {
  const urlString = `${ACCOUNT_PAGE_URL_PREFIX}phone-authentication/`;
  return { pathname: urlString, query: query };
};
export const getPhoneAuthenticationResultPageUrlObject = (query: {
  redirectUrl: string;
}): UrlObject => {
  const urlString = `${ACCOUNT_PAGE_URL_PREFIX}phone-authentication/result/`;
  return { pathname: urlString, query: query };
};
export const getLoginPageUrlObject = (query: {
  redirectUrl?: string;
}): UrlObject => {
  const urlString = `${ACCOUNT_PAGE_URL_PREFIX}login/`;
  return { pathname: urlString, query: query };
};
