import { authAPI } from '@apis/core/instance';

export const getTeacherUserList = async () => {
  const teacherList = await authAPI
    .get(`/api/teachers/teacher-info/?pageType=side-bar`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return teacherList;
};
