import React from 'react';

const ChevronRight = ({
  width = '16',
  height = '16',
  color = 'var(--blue-50)',
  onClick = null,
  classes = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      className={`${onClick ? 'cursor-pointer' : ''} ${classes}`}
    >
      <path
        d="M13.675 12L9.775 8.1C9.59167 7.91666 9.5 7.68333 9.5 7.4C9.5 7.11666 9.59167 6.88333 9.775 6.7C9.95833 6.51666 10.1917 6.425 10.475 6.425C10.7583 6.425 10.9917 6.51666 11.175 6.7L15.775 11.3C15.875 11.4 15.9458 11.5083 15.9875 11.625C16.0292 11.7417 16.05 11.8667 16.05 12C16.05 12.1333 16.0292 12.2583 15.9875 12.375C15.9458 12.4917 15.875 12.6 15.775 12.7L11.175 17.3C10.9917 17.4833 10.7583 17.575 10.475 17.575C10.1917 17.575 9.95833 17.4833 9.775 17.3C9.59167 17.1167 9.5 16.8833 9.5 16.6C9.5 16.3167 9.59167 16.0833 9.775 15.9L13.675 12Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronRight;
