import {
  basicButtonSizeAssetGroup,
  buttonIconSizeAssetGroup,
  getBasicButtonColorAsset,
} from '@asset/design/buttonAsset';
import { IconButtonProps } from '@asset/type/common/buttonType';
import { IconButtonStyle } from '@styles/common/button/buttonStyles';
import Image from 'next/image';
import React from 'react';

const IconButton = ({
  color,
  assetType,
  size,
  content,
  onClickEvent,
  isActive = true,
  buttonType = null,
  width = 'fit-content',
  leftIcon = null,
  rightIcon = null,
}: IconButtonProps) => {
  const colorAsset = getBasicButtonColorAsset(color, assetType);
  const sizeAsset = basicButtonSizeAssetGroup[size];
  const iconSizeAsset = buttonIconSizeAssetGroup[size];

  return (
    <IconButtonStyle
      onClick={(e) => {
        if (onClickEvent && isActive) onClickEvent(e);
      }}
      type={buttonType}
      colorAsset={colorAsset}
      sizeAsset={sizeAsset}
      isActive={isActive}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      width={width}
    >
      {leftIcon &&
        (typeof leftIcon === 'string' ? (
          <Image
            src={leftIcon}
            width={iconSizeAsset.pxWidth}
            height={iconSizeAsset.pxHeight}
          />
        ) : (
          leftIcon
        ))}
      <div>{content}</div>
      {rightIcon &&
        (typeof rightIcon === 'string' ? (
          <Image
            src={rightIcon}
            width={iconSizeAsset.pxWidth}
            height={iconSizeAsset.pxHeight}
          />
        ) : (
          rightIcon
        ))}
    </IconButtonStyle>
  );
};

export default IconButton;
