import { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { SmallLeftArrowIcon, SmallRightArrowIcon } from '../icon/ArrowIcon';
import { convertTimezone } from '@asset/function/i18n';

interface Props {
  startDay: Dayjs;
  endDay?: Dayjs;
  arrowOnClickEvent: ({
    startDay,
    endDay,
  }: {
    startDay: Dayjs;
    endDay: Dayjs;
  }) => void;
  timezone?: string | null;
  textOnClickEvent?: () => void;
  unit?: 'week' | 'day';
  format?: string | ((startDay: Dayjs) => string);
  clickable?: boolean | { previous: boolean; next: boolean };
}

const DateCarousel = ({
  startDay,
  endDay,
  arrowOnClickEvent,
  textOnClickEvent,
  timezone = null,
  unit = 'week',
  format = null,
  clickable = true,
}: Props) => {
  const [isClickablePrevious, setIsClickablePrevious] = useState(
    (typeof clickable === 'boolean' && clickable) ||
      (typeof clickable === 'object' && clickable.previous),
  );
  const [isClickableNext, setIsClickableNext] = useState(
    (typeof clickable === 'boolean' && clickable) ||
      (typeof clickable === 'object' && clickable.next),
  );

  const getEndDay = useCallback(() => {
    if (unit === 'week') {
      return startDay.add(6, 'day');
    }
  }, [startDay]);

  const getText = useCallback(() => {
    if (typeof format === 'function') {
      return format(startDay);
    } else {
      return `${startDay.format(format ?? 'MM.DD')} - ${
        endDay
          ? endDay.format(format ?? 'MM.DD')
          : getEndDay().format(format ?? 'MM.DD')
      }`;
    }
  }, [format, startDay, endDay]);

  useEffect(() => {
    setIsClickablePrevious(
      (typeof clickable === 'boolean' && clickable) ||
        (typeof clickable === 'object' && clickable.previous),
    );
    setIsClickableNext(
      (typeof clickable === 'boolean' && clickable) ||
        (typeof clickable === 'object' && clickable.next),
    );
  }, [clickable]);

  return (
    <div className="flex items-center gap-2 text-my-gray-30 text-label-sm-700">
      <button
        onClick={() => {
          if (isClickablePrevious) {
            const _endDay = endDay ? endDay : getEndDay();
            let previousStartDay = null;
            let previousEndDay = null;

            if (unit === 'week') {
              const startDayOfWeekIndex = startDay.day();
              const endDayOfWeekIndex = _endDay.day();
              previousStartDay = startDay.day(-(7 - startDayOfWeekIndex));
              previousEndDay = _endDay.day(-(7 - endDayOfWeekIndex));
            } else {
              const dayCount = endDay.diff(startDay, 'd');
              previousStartDay = startDay.subtract(dayCount + 1, unit);
              previousEndDay = _endDay.subtract(dayCount + 1, unit);
            }

            if (timezone && startDay.utcOffset() !== 0) {
              previousStartDay = convertTimezone(
                previousStartDay,
                timezone,
                true,
              );
              previousEndDay = convertTimezone(previousEndDay, timezone, true);
            }

            arrowOnClickEvent({
              startDay: previousStartDay,
              endDay: previousEndDay,
            });
          } else {
            return;
          }
        }}
      >
        <SmallLeftArrowIcon
          color={isClickablePrevious ? 'var(--gray-30)' : 'var(--gray-60)'}
          className={isClickablePrevious ? 'cursor-pointer' : ''}
        ></SmallLeftArrowIcon>
      </button>
      <span
        className={`flex justify-center w-20 ${
          textOnClickEvent ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          textOnClickEvent && textOnClickEvent();
        }}
      >
        {getText()}
      </span>
      <button
        onClick={() => {
          if (isClickableNext) {
            const _endDay = endDay ? endDay : getEndDay();
            let nextStartDay = null;
            let nextEndDay = null;

            if (unit === 'week') {
              const startDayOfWeekIndex = startDay.day();
              const endDayOfWeekIndex = _endDay.day();
              nextStartDay = startDay.day(7 + startDayOfWeekIndex);
              nextEndDay = _endDay.day(7 + endDayOfWeekIndex);
            } else {
              const dayCount = endDay.diff(startDay, 'd');
              nextStartDay = startDay.add(dayCount + 1, unit);
              nextEndDay = _endDay.add(dayCount + 1, unit);
            }

            if (timezone && startDay.utcOffset() !== 0) {
              nextStartDay = convertTimezone(nextStartDay, timezone, true);
              nextEndDay = convertTimezone(nextEndDay, timezone, true);
            }

            arrowOnClickEvent({
              startDay: nextStartDay,
              endDay: nextEndDay,
            });
          } else {
            return;
          }
        }}
      >
        <SmallRightArrowIcon
          color={isClickableNext ? 'var(--gray-30)' : 'var(--gray-60)'}
          className={isClickableNext ? 'cursor-pointer' : ''}
        ></SmallRightArrowIcon>
      </button>
    </div>
  );
};

export default DateCarousel;
