import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getSettedTimezoneDayjs = (timezone) => {
  const persistAtom = localStorage.getItem('persistAtom');
  let loginUserInfo = null;
  if (persistAtom) loginUserInfo = JSON.parse(persistAtom).loginUserInfo;

  const settingTimezone = timezone
    ? timezone
    : loginUserInfo?.timezone
    ? loginUserInfo?.timezone
    : dayjs.tz.guess();
  dayjs.tz.setDefault(settingTimezone);

  return dayjs.tz;
};

export const convertTimezone = (time, toNation, timezoneOnly) => {
  const toNationTimezone = toNation ? toNation : dayjs.tz.guess();
  return dayjs(time).tz(toNationTimezone, timezoneOnly);
};

export const convertTimezoneThroughUTC = (time, toNation) => {
  const toNationTimezone = toNation ? toNation : dayjs.tz.guess();
  return dayjs.utc(time).tz(toNationTimezone);
};

export const languageCodeObject = {
  ko: '한국어',
  en: 'English',
};
