import BasicArrowTooltip from '@components/common/tool-tip/BasicArrowTooltip';
import React from 'react';

interface MyClassTextButtonProps {
  content: number | string | JSX.Element;
  canHover: boolean;
  onClickEvent: null | (() => void);
  tooltipTitle?: string | null;
}

const MyClassTextButton = ({
  content,
  canHover,
  onClickEvent,
  tooltipTitle = null,
}: MyClassTextButtonProps) => {
  return (
    <div className={`flex w-full pl-2`}>
      <BasicArrowTooltip
        title={tooltipTitle ? tooltipTitle : ''}
        disableHoverListener={tooltipTitle === null}
        placement="top"
        arrow
      >
        <div
          className={`w-8 h-8 rounded-lg flex items-center justify-center text-label-sm-600 text-my-gray-30 ${
            canHover ? 'cursor-pointer hover:bg-my-gray-80' : ''
          } `}
          onClick={() => onClickEvent && onClickEvent()}
        >
          {content}
        </div>
      </BasicArrowTooltip>
    </div>
  );
};

export default MyClassTextButton;
