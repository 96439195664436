import React, { useEffect, useRef, useState } from 'react';
import { SelectStudentDropdownStyle } from '@styles/common/dropDown/dropDownStyles';
import { selectedStudentIdAtom } from '@atoms/states';
import { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';
import { useEventPropertiesStore } from '@store/common';
import Select, { components } from 'react-select';
import { changeKeyName } from '@asset/function/commonFunctions';
import Image from 'next/image';
import { studentInfoFormSelectCustomStyle } from '@styles/common/formStyles';
import Link from 'next/link';
import { useSelectedStudentInfoQuery } from '@apis/query/user';
import { useRecoilState } from 'recoil';
import { StudyContentType } from '@asset/enum/materialEnum';
import FilterIcon from '@asset/svg/FilterIcon';
import BasicMenu, { BasicMenuItemContent } from '../list/BasicMenu';
import CheckIcon from '@asset/svg/CheckIcon';
import * as S from '@styles/styles';

export enum StudentGroupType {
  MY_STUDENTS = 'my-students',
  ALL_STUDENTS = 'all-students',
}
interface Props {
  studentList: { id: number; name: string; schoolName: string }[];
  studentGroupFiltering: StudentGroupType;
  onSelectFiltering: (studentGroupFiltering: StudentGroupType) => void;
}

export default function SelectStudentDropdown({
  studentList,
  studentGroupFiltering,
  onSelectFiltering,
}: Props) {
  const router = useRouter();
  const { properties } = useEventPropertiesStore();
  const { data: selectedStudent } = useSelectedStudentInfoQuery();
  const [, setSelectedStudentId] = useRecoilState(selectedStudentIdAtom);

  const openDropDownRef = useRef(null);
  const dropDownInputRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [pageType, setPageType] = useState('main');

  const CustomControl = ({ children, ...props }: any) => {
    const [isOpenFiltering, setIsOpenFiltering] = useState(false);
    const listContent = {
      [StudentGroupType.MY_STUDENTS]: '나의 학생만 보기',
      [StudentGroupType.ALL_STUDENTS]: '전체 학생 보기',
    };
    return (
      <S.SelectStudentDropdownCustomControl>
        <components.Control {...props}>
          <Image src={'/common/icon/search.svg'} width={24} height={24}></Image>
          {children}
        </components.Control>
        <button
          className="filter-button"
          onClick={() => setIsOpenFiltering(true)}
        >
          <FilterIcon />
        </button>
        {isOpenFiltering && (
          <BasicMenu
            items={[
              {
                id: StudentGroupType.MY_STUDENTS,
                content:
                  studentGroupFiltering === StudentGroupType.MY_STUDENTS ? (
                    <BasicMenuItemContent
                      icon={<CheckIcon color="var(--blue-50)" />}
                      textColor={'text-my-blue-50'}
                      text={listContent[StudentGroupType.MY_STUDENTS]}
                    />
                  ) : (
                    listContent[StudentGroupType.MY_STUDENTS]
                  ),
              },
              {
                id: StudentGroupType.ALL_STUDENTS,
                content:
                  studentGroupFiltering === StudentGroupType.ALL_STUDENTS ? (
                    <BasicMenuItemContent
                      icon={<CheckIcon color="var(--blue-50)" />}
                      textColor={'text-my-blue-50'}
                      text={listContent[StudentGroupType.ALL_STUDENTS]}
                    />
                  ) : (
                    listContent[StudentGroupType.ALL_STUDENTS]
                  ),
              },
            ]}
            onClickAway={() => setIsOpenFiltering(false)}
            onClickItem={(selectedItem) => {
              onSelectFiltering(selectedItem.id as StudentGroupType);
              setIsOpenFiltering(false);
            }}
          />
        )}
      </S.SelectStudentDropdownCustomControl>
    );
  };
  const CustomOption = ({ children, ...props }: any) => {
    return (
      <components.Option
        {...props}
        isSelected={selectedStudent?.id === props.data.value}
      >
        {children}
        <span>{props.data.schoolName}</span>
      </components.Option>
    );
  };

  const focusOutItemListHandler = (event) => {
    if (
      dropDownInputRef.current &&
      openDropDownRef.current &&
      !openDropDownRef.current.contains(event.target) &&
      !dropDownInputRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', focusOutItemListHandler);
  }, []);

  useEffect(() => {
    if (router.isReady) {
      setPageType(
        router.asPath.startsWith('/manage/dashboard/')
          ? router.query.type
            ? router.query?.type.length == 1
              ? 'main'
              : router.query?.type[0]
            : 'main'
          : 'main',
      );
    }
  }, [router]);

  return (
    <>
      <div className="flex items-center gap-2 text-label-lg-500 text-my-gray-100">
        {selectedStudent ? (
          <Link href={`/manage/dashboard/main/${selectedStudent.id}`}>
            <a className="main-student-name">{selectedStudent.name}</a>
          </Link>
        ) : (
          <span className="main-student-name">학생 이름</span>
        )}

        <button
          aria-label="toggle menu"
          type="button"
          ref={openDropDownRef}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <img src="/common/icon/gnb/list_arrow_icon.svg"></img>
        </button>
      </div>
      <SelectStudentDropdownStyle>
        <div ref={dropDownInputRef}>
          {isOpen ? (
            <Select
              autoFocus
              options={changeKeyName(
                studentList,
                ['id', 'name'],
                ['value', 'label'],
              )}
              noOptionsMessage={() => '검색결과 없음'}
              menuIsOpen={true}
              isSearchable={true}
              backspaceRemovesValue={true}
              components={{
                Control: CustomControl,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: CustomOption,
              }}
              closeMenuOnSelect={false}
              styles={studentInfoFormSelectCustomStyle}
              placeholder={'학생 검색'}
              onChange={async (selectedValue: any) => {
                setSelectedStudentId(selectedValue.value);
                router.push(
                  pageType === StudyContentType.MY_STUDY_MATERIAL ||
                    pageType === StudyContentType.MY_TEST
                    ? `/manage/dashboard/${pageType}/${selectedValue.value}?isHidden=false`
                    : `/manage/dashboard/${pageType}/${selectedValue.value}`,
                );
                setIsOpen(!isOpen);
                amplitude.track(`click_main_nav_student_list_drop_down`, {
                  ...properties,
                  studentId: selectedValue?.value,
                });
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </SelectStudentDropdownStyle>
    </>
  );
}
