import { getUrlByQueryParameterObj } from '@asset/function/queryStringFunctions';
import { authAPI } from 'apis/core/instance';

export const getMaterialPageList = async (queryStringObj) => {
  let url = `/api/materials/list/`;
  if (queryStringObj) {
    const queryString = getUrlByQueryParameterObj('', queryStringObj);
    url += queryString;
  }
  const data = await authAPI
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const updateMaterial = async (materialId, isHidden) => {
  const url = `/api/materials/update/?materialId=${materialId}&isHidden=${isHidden}`;

  const data = await authAPI
    .patch(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getMaterialFilterList = async () => {
  const url = `/api/materials/list/filter/`;

  const data = await authAPI
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getMaterialNameInfo = async (studyMaterialId) => {
  const data = await authAPI
    .get(`/api/materials/info/${studyMaterialId}/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getMaterialInformation = async (
  studyMaterialId: number,
): Promise<{
  success: boolean;
  id: number;
  subject: string;
  course: string;
  materialType: string;
  studyMaterialName: string;
  description: string;
  isHidden: boolean;
}> => {
  const data = await authAPI
    .get(`/api/materials/${studyMaterialId}/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const patchMaterialInformation = async (
  studyMaterialId: number,
  reqData: {
    subject?: string;
    course?: string;
    materialType?: string;
    studyMaterialName?: string;
    description?: string;
    isHidden?: boolean;
  },
): Promise<{ success: boolean; message: string }> => {
  const data = await authAPI
    .patch(`/api/materials/${studyMaterialId}/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const postNewMaterial = async (reqData) => {
  const data = await authAPI
    .post(`/api/materials/new/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const validateMaterial = async (reqData) => {
  const data = await authAPI
    .post(`/api/materials/duplicate-check/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const postMaterialInfo = async (reqData) => {
  const data = await authAPI
    .post(`/api/materials/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const patchMaterialContent = async (reqData) => {
  const data = await authAPI
    .patch(`/api/materials/spreadsheet/media/`, reqData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const getSpreadSheetRowData = async (studyMaterialId) => {
  const data = await authAPI
    .get(`/api/materials/spreadsheet/?studyMaterialId=${studyMaterialId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
export const patchSpreadSheetRow = async (reqData) => {
  const data = await authAPI
    .patch(`/api/materials/spreadsheet/row/`, reqData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
