import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as amplitude from '@amplitude/analytics-browser';
import Select, { components } from 'react-select';
import { changeKeyName } from '@asset/function/commonFunctions';
import BasicButton from './button/BasicButton';
import CloseIcon from '@asset/svg/CloseIcon';
import { encodeQueryValue } from '@asset/function/queryStringFunctions';

const CustomControl = ({ children, ...props }: any) => {
  const [valueList, setValueList] = useState(props.getValue());
  const scrollRef = useRef(null);

  useEffect(() => {
    setValueList(props.getValue());
    if (scrollRef?.current)
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
      });
  }, [props.getValue(), scrollRef?.current]);

  return (
    <div className="flex flex-col gap-2 p-2">
      <components.Control {...props}> {children}</components.Control>
      <div
        className="flex flex-wrap gap-1 overflow-y-scroll max-h-24"
        ref={scrollRef}
      >
        {valueList.map((obj, index) => {
          return (
            <div
              key={index}
              className="flex items-center gap-1 px-3 py-2 bg-my-blue-50 rounded-3xl text-my-gray-100 text-label-sm-500"
            >
              {obj.label}
              <div
                onClick={() => {
                  let newList = [...valueList];
                  newList = newList.filter((el) => el.value !== obj.value);
                  setValueList(newList);
                  props.setValue(newList);
                }}
                className="cursor-pointer"
              >
                <CloseIcon color="var(--gray-100)" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const InputOption = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: any) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(true);
  const onMouseLeave = () => setIsActive(false);

  let bg = 'transparent';
  if (isSelected) bg = 'var(--gray-80)';
  if (isFocused) bg = 'var(--gray-90)';
  if (isActive) bg = 'var(--blue-95)';

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'var(--gray-10)',
    display: 'flex ',
    cursor: 'pointer',
    gap: '0.5rem',
    padding: '0.62rem 1rem',
    fontSize: '0.875rem',
    fontWeight: '500',
  };

  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      {children}
    </components.Option>
  );
};

const MultiSearchFilterBox = ({
  name,
  filterValueList,
  defaultPickedOptionList,
  onClickCancel,
  onSubmitEvent,
  className,
  pageType = '',
}) => {
  const [optionList, setOptionList] = useState([]);
  const [pickedOptionList, setPickedOptionList] = useState([]);
  const methods = useForm({
    defaultValues: {
      [name]: defaultPickedOptionList
        ? typeof defaultPickedOptionList === 'string'
          ? defaultPickedOptionList.split(',')
          : defaultPickedOptionList
        : [],
    },
    mode: 'onChange',
  });
  const customStyles = {
    control: (base) => ({
      ...base,
      maxHeight: '4.875rem',
      overflow: 'auto',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: '100%',
    }),
    menu: (base) => ({
      ...base,
      position: 'static',
      borderTop: '1px solid var(--gray-70)',
      borderBottom: '1px solid var(--gray-70)',
      boxShadow: 'none',
      borderRadius: '0px',
      margin: '0',
    }),
    menuList: (base) => ({
      ...base,
      display: 'flex',
      flexDirection: 'column',
      padding: '0.25rem 0',
    }),
    placeholder: (base) => ({
      ...base,
      color: 'var(--gray-60)',
      fontSize: '0.875rem',
      fontWeight: '500',
      textAlign: 'left',
    }),
    multiValue: (base) => ({
      ...base,
      display: 'none',
    }),
  };
  const selectEventHandler = useCallback(() => {
    const searchWrapper = document.querySelector('.control');
    searchWrapper?.scrollTo(0, searchWrapper.scrollHeight);
  }, []);

  const submitEventHandler = (result) => {
    amplitude.track(`click_teacher_${pageType}_filter_${name}`, {
      value: result[name],
    });

    if (onSubmitEvent) onSubmitEvent(result);
    onClickCancel();
  };

  useEffect(() => {
    const newOptionList = changeKeyName(
      filterValueList,
      ['id', 'name'],
      ['value', 'label'],
    );
    setOptionList(newOptionList);
  }, [filterValueList]);

  useEffect(() => {
    if (defaultPickedOptionList) {
      const _pickedValueList = filterValueList.filter(
        (valueObj) =>
          defaultPickedOptionList &&
          (defaultPickedOptionList.includes(valueObj.id.toString()) ||
            defaultPickedOptionList.includes(encodeQueryValue(valueObj.id))),
      );
      const newKeyObjList = changeKeyName(
        _pickedValueList,
        ['id', 'name'],
        ['value', 'label'],
      );
      setPickedOptionList(newKeyObjList);
    }
  }, [defaultPickedOptionList]);

  return (
    <form
      className={`absolute w-[15.375rem] top-[100%] flex flex-col justify-center items-start z-[3] bg-my-gray-100 rounded-xl border border-solid border-my-gray-70 ${className}`}
      style={{ boxShadow: '0px 8px 24px 0px rgba(25, 32, 41, 0.16)' }}
      onSubmit={methods.handleSubmit(submitEventHandler)}
    >
      <FormProvider {...methods}>
        <Controller
          name={name}
          render={({ field }) => {
            return (
              <Select
                options={optionList}
                menuIsOpen={true}
                isMulti
                isClearable={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option: InputOption,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  Control: CustomControl,
                }}
                value={pickedOptionList}
                styles={customStyles}
                placeholder={'검색'}
                onChange={(selectedList: any) => {
                  setPickedOptionList(selectedList);
                  const newSelectedList = changeKeyName(
                    selectedList,
                    ['value', 'label'],
                    ['id', 'name'],
                  );
                  field.onChange(newSelectedList);
                  selectEventHandler();
                }}
              />
            );
          }}
        />
        <section className="flex justify-end w-full gap-2 p-2 display">
          <BasicButton
            color="gray"
            assetType={'Tertiary'}
            size={'S'}
            content={'취소'}
            onClickEvent={() => onClickCancel()}
            isActive={true}
          />
          <BasicButton
            color="blue"
            assetType={'Primary'}
            size={'S'}
            content={'적용'}
            onClickEvent={null}
            isActive={true}
            buttonType="submit"
          />
        </section>
      </FormProvider>
      <style jsx global>
        {`
          form {
            > div {
              width: 100%;
            }
          }
          #react-select-2-listbox {
            ::-webkit-scrollbar {
              display: block !important;
            }
          }
        `}
      </style>
    </form>
  );
};

export default MultiSearchFilterBox;
