import ChevronLeft from '@asset/svg/ChevronLeft';
import ChevronRight from '@asset/svg/ChevronRight';
import dayjs from 'dayjs';
import React from 'react';

const FormDatePickerCalendarHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <div className={`w-full flex justify-between items-center mb-2`}>
      {!prevMonthButtonDisabled ? (
        <ChevronLeft
          color={'var(--gray-10)'}
          width="24"
          height="24"
          onClick={decreaseMonth}
        ></ChevronLeft>
      ) : (
        <div className="w-6"></div>
      )}
      <div className={`flex gap-2 text-label-lg-600 text-my-gray-10`}>
        <div>{dayjs(date).get('year')}년</div>
        <div>{dayjs(date).get('month') + 1}월</div>
      </div>
      {!nextMonthButtonDisabled ? (
        <ChevronRight
          color={'var(--gray-10)'}
          width="24"
          height="24"
          onClick={increaseMonth}
        ></ChevronRight>
      ) : (
        <div className="w-6"></div>
      )}
    </div>
  );
};

export default FormDatePickerCalendarHeader;
