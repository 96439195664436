import { UrlObject } from 'url';

export const MANAGE_COURSE_LIST_URL_PREFIX = `/manage/course-list`;
export const MANAGE_COURSE_DETAIL_URL_PREFIX = `/manage/course/detail/`;
export const MY_COURSE_DETAIL_URL_PREFIX = `/course/detail/`;
export const EDIT_COURSE_URL_PREFIX = `/manage/course/edit/`;
export const CREATE_COURSE_URL_PREFIX = `/manage/course/create/`;

export const getManageCourseDetailUrlObject = (
  itemId: number,
  query: { studentId: number },
): UrlObject => {
  const urlString = `${MANAGE_COURSE_DETAIL_URL_PREFIX}${itemId}`;
  return { pathname: urlString, query };
};

export const getManageCourseListUrlObject = (): UrlObject => {
  const urlString = `${MANAGE_COURSE_LIST_URL_PREFIX}?updatedAt=desc&showHidden=hide`;
  return { pathname: urlString };
};

export const getMyCourseDetailUrlObject = (myCourseId): UrlObject => {
  const urlString = `${MY_COURSE_DETAIL_URL_PREFIX}${myCourseId}`;
  return { pathname: urlString };
};

export const getEditCourseUrlObject = (
  courseId,
  query: {
    subject: string;
    materialCourse: string;
    title: string;
  },
): UrlObject => {
  const urlString = `${EDIT_COURSE_URL_PREFIX}${courseId}`;
  return { pathname: urlString, query: query };
};

export const getCreateCourseUrlObject = (query: {
  id?: number;
  subject: string;
  materialCourse: string;
  title: string;
}): UrlObject => {
  const urlString = `${CREATE_COURSE_URL_PREFIX}`;
  return { pathname: urlString, query: query };
};
