import styled from 'styled-components';
import tw from 'twin.macro';

export const MenuBoxStyle = styled.div`
  & {
    ${tw`py-1 bg-my-gray-100 flex flex-col rounded-lg py-1 z-[1] mx-2`}
    box-shadow: 0px 4px 16px 0px rgba(25, 32, 41, 0.16);
    z-index: 100;
    width: ${(props) => (props.width ? props.width : '')};
    position: ${(props) => (props.position ? props.position : '')};
    top: ${(props) => (props.top ? props.top : '')};
    right: ${(props) => (props.right ? props.right : '')};
    left: ${(props) => (props.left ? props.left : '')};
    bottom: ${(props) => (props.bottom ? props.bottom : '')};

    > span {
      ${tw`px-4 py-3 cursor-pointer hover:bg-my-gray-90 active:bg-my-gray-80 text-label-lg-500 text-my-gray-10`}
    }
  }
`;
