import BasicDropDown from './BasicDropDown';
import { ListTableFilterStyle } from '@styles/common/dropDown/dropDownStyles';

export type DropDownProps = {
  filterType: string;
  dropDownMenu: { title: string; enum?: string }[];
  onClickEvent?;
};

export function ListFilterDropDown({
  filterType,
  dropDownMenu,
  onClickEvent,
}: DropDownProps) {
  return (
    <>
      <ListTableFilterStyle width={'9rem'}>
        <BasicDropDown
          filterType={filterType}
          dropDownMenu={dropDownMenu}
          onSelect={
            onClickEvent
              ? (item) => {
                  if (item.enum || Object.keys(item).includes('enum')) {
                    onClickEvent(item?.enum);
                  } else {
                    onClickEvent(item);
                  }
                }
              : null
          }
        ></BasicDropDown>
      </ListTableFilterStyle>
    </>
  );
}
