import { ClickAwayListener } from '@mui/material';
import * as S from '@styles/styles';
import React from 'react';

interface MenuItem {
  id: string | number;
  content: string | JSX.Element;
  selected?: boolean;
}

interface BasicMenuProps {
  menuTitle?: string;
  items: MenuItem[];
  onClickAway: () => void;
  onClickItem: (selectedItem: MenuItem) => void;
  customStyles?: { top?: string; right?: string; transform?: string };
}

interface BasicMenuItemContentProps {
  icon: JSX.Element;
  text: string;
  textColor?: string;
}

export const BasicMenuItemContent = ({
  icon,
  text,
  textColor = '',
}: BasicMenuItemContentProps) => {
  return (
    <S.BasicMenuItemContent
      className={` ${textColor ? textColor : 'text-my-gray-10'}`}
    >
      {icon}
      <span>{text}</span>
    </S.BasicMenuItemContent>
  );
};

const BasicMenu = ({
  menuTitle = null,
  items,
  onClickAway,
  onClickItem,
  customStyles,
}: BasicMenuProps) => {
  return (
    <ClickAwayListener
      onClickAway={() => onClickAway()}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <S.BasicMenu customStyles={customStyles}>
        {menuTitle && <span>{menuTitle}</span>}
        {items.map((menu) => (
          <li
            key={menu.id}
            className={menu.selected ? 'selected' : ''}
            onClick={(e) => {
              onClickItem(menu);
              e.stopPropagation();
            }}
          >
            {menu.content}
          </li>
        ))}
      </S.BasicMenu>
    </ClickAwayListener>
  );
};

export default BasicMenu;
