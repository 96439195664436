import React from 'react';

const AddIcon = ({ width = '20', height = '20', color = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.16675 10.8337H5.00008C4.76397 10.8337 4.56605 10.7538 4.40633 10.5941C4.24661 10.4344 4.16675 10.2364 4.16675 10.0003C4.16675 9.76421 4.24661 9.5663 4.40633 9.40658C4.56605 9.24685 4.76397 9.16699 5.00008 9.16699H9.16675V5.00033C9.16675 4.76421 9.24661 4.5663 9.40633 4.40658C9.56605 4.24685 9.76397 4.16699 10.0001 4.16699C10.2362 4.16699 10.4341 4.24685 10.5938 4.40658C10.7536 4.5663 10.8334 4.76421 10.8334 5.00033V9.16699H15.0001C15.2362 9.16699 15.4341 9.24685 15.5938 9.40658C15.7536 9.5663 15.8334 9.76421 15.8334 10.0003C15.8334 10.2364 15.7536 10.4344 15.5938 10.5941C15.4341 10.7538 15.2362 10.8337 15.0001 10.8337H10.8334V15.0003C10.8334 15.2364 10.7536 15.4344 10.5938 15.5941C10.4341 15.7538 10.2362 15.8337 10.0001 15.8337C9.76397 15.8337 9.56605 15.7538 9.40633 15.5941C9.24661 15.4344 9.16675 15.2364 9.16675 15.0003V10.8337Z"
        fill={color}
      />
    </svg>
  );
};

export default AddIcon;
