import { styled } from '@mui/material';
import Pagination from '@mui/material/Pagination';
const BasicPagination = styled(Pagination)({
  '& .Mui-selected': {
    backgroundColor: 'var(--gray-30)!important',
    color: 'var(--gray-100)!important',
    border: '1px solid var(--gray-30)!important',
  },
  '& .MuiPaginationItem-root': {
    border: '1px solid var(--gray-60)',
    backgroundColor: 'var(--gray-100)',
    width: '2rem',
    height: '2rem',
    borderRadius: '0.25rem!important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .Mui-disabled': {
    color: 'var(--gray-60)',
    opacity: '1!important',
  },
});
export default BasicPagination;
