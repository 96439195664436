import qs from 'qs';

export const isEncoded = (str) => {
  return (
    typeof str == 'string' &&
    decodeURIComponent(str) !== str &&
    str.match(/[_\.!~*'()-]/) &&
    str.match(/%[0-9a-f]{2}/i)
  );
};

export const getUrlByQueryParameterObj = (
  defaultQueryString,
  queryStringObj,
) => {
  let queryString = defaultQueryString;
  for (const queryKey in queryStringObj) {
    if (
      typeof queryStringObj[queryKey] === 'boolean' ||
      queryStringObj[queryKey]
    ) {
      const queryValue = queryStringObj[queryKey];
      queryString +=
        queryString === ''
          ? `?${queryKey}=${queryValue}`
          : `&${queryKey}=${queryValue}`;
    }
  }
  return queryString;
};

export const makeQueryStringObj = (
  defaultQuerySting: object,
  queryStringObj: object,
  pathQuery: string[],
) => {
  const totalQueryObj = Object.assign(defaultQuerySting, queryStringObj);
  pathQuery.map((queryName) => delete totalQueryObj[`${queryName}`]);
  return totalQueryObj;
};

export const makeQueryString = (
  defaultQuerySting: object,
  queryStringObj: object,
  pathQuery: string[], //파일 구조 path에 넣는 query
) => {
  const totalQueryObj = makeQueryStringObj(
    defaultQuerySting,
    queryStringObj,
    pathQuery,
  );
  let queryString = '';
  for (const queryKey in totalQueryObj) {
    const queryValue = totalQueryObj[queryKey];
    if (queryValue) {
      if (typeof queryValue === 'object') {
        queryString +=
          queryString === ''
            ? `?${queryKey}=${JSON.stringify(queryValue)}`
            : `&${queryKey}=${JSON.stringify(queryValue)}`;
      } else {
        queryString +=
          queryString === ''
            ? `?${queryKey}=${queryValue}`
            : `&${queryKey}=${queryValue}`;
      }
    }
  }

  return queryString;
};

export const encodeQueryValue = (value) => {
  return qs
    .stringify(
      { a: value },
      {
        arrayFormat: 'comma',
        encodeValuesOnly: true,
      },
    )
    .replace(`a=`, '');
};

export const getMyClassFiltering = (queryObj, notStoreKeyList) => {
  const keyList = Object.keys(queryObj);

  const newQueryObj = {};
  for (let i = 0; i < keyList.length; i++) {
    const queryKey = keyList[i];
    if (!notStoreKeyList.includes(queryKey)) {
      newQueryObj[queryKey] = queryObj[queryKey];
    }
  }
  return newQueryObj;
};

export const convertOrderByQueries = (
  keyMap: object,
  routerQueries,
): string[] => {
  const result = [];
  const urlQueryKeys = Object.keys(keyMap);
  const apiQueryKeys = Object.values(keyMap);
  for (let i = 0; i < apiQueryKeys.length; i++) {
    const urlQueryKey = urlQueryKeys[i];
    const apiQueryKey = apiQueryKeys[i];
    if (routerQueries[urlQueryKey]) {
      result.push(
        `${routerQueries[urlQueryKey] === 'asc' ? '' : '-'}${apiQueryKey}`,
      );
    }
  }
  return result;
};

export const joinElementsInArrayWithEncoding = (
  targetArray: string[],
): string => {
  return qs
    .stringify(
      { a: targetArray },
      { arrayFormat: 'comma', encodeValuesOnly: true },
    )
    .replace('a=', '');
};
