export const tagAsset = {
  blue: {
    backgroundColor: 'var(--blue-50)',
    hoverBackgroundColor: 'var(--blue-50)',
    textColor: 'white',
  },
  'light-blue': {
    backgroundColor: 'var(--blue-95)',
    hoverBackgroundColor: 'var(--blue-90)',
    textColor: 'var(--blue-50)',
  },
  'light-yellow': {
    backgroundColor: '#FFFBD9',
    hoverBackgroundColor: '#FFFBD9',
    textColor: '#8B6911',
  },
  'light-green': {
    backgroundColor: 'var(--green-95)',
    hoverBackgroundColor: 'var(--green-90)',
    textColor: 'var(--green-40)',
  },
  'light-red': {
    backgroundColor: 'var(--red-95)',
    hoverBackgroundColor: 'var(--red-90)',
    textColor: 'var(--red-50)',
  },
  'light-orange': {
    backgroundColor: 'var(--orange-95)',
    hoverBackgroundColor: 'var(--orange-90)',
    textColor: 'var(--orange-50)',
  },
  'light-purple': {
    backgroundColor: 'var(--purple-95)',
    hoverBackgroundColor: 'var(--purple-90)',
    textColor: 'var(--purple-50)',
  },
  'light-pink': {
    backgroundColor: '#FFEEFC',
    hoverBackgroundColor: '#FFEEFC',
    textColor: 'var(--old-grey-700)',
  },
  'light-gray': {
    backgroundColor: 'var(--gray-90)',
    hoverBackgroundColor: 'var(--gray-85)',
    textColor: 'var(--gray-40)',
  },
};
