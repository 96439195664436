import { getMyClassWeeklyGoalApi } from '@apis/api/teacher/class';
import { useUserInfoQuery } from '@apis/query/user';
import {
  CommonListFilteringKey,
  CommonListColumnData,
  ListFilter,
} from '@components/common/list/CommonList';
import CommonList from '@components/common/list/CommonList';
import { useRouter } from 'next/router';
import {
  myClassListKey,
  MY_CLASS_PATH_QUERY,
  isRouterHasFilteringQuery,
} from 'pages/manage/my-class/[...myClassType]';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMyClassWeeklyGoalFilteringQueryStore } from '@store/filteringQueryStore';
import MyClassStudentInfoCell from './MyClassStudentInfoCell';
import dayjs from 'dayjs';
import MyClassMissionStatusCell from './MyClassMissionStatusCell';
import MyClassStudyTimeStatusCell from './MyClassStudyTimeStatusCell';
import { ParsedUrlQueryInput } from 'querystring';
import { useQuery } from 'react-query';
import { DateTime } from 'luxon';

const MyClassWeeklyGoalPage = ({
  filteringStudentList,
  filteringTeacherList,
}) => {
  const PAGE_SIZE = 30;
  const TODAY_TIMESTAMP = DateTime.now().startOf('day').toSeconds();
  const router = useRouter();
  const { data: user } = useUserInfoQuery();
  const {
    urlQueryObj: myClassWeeklyGoalUrlQueryObj,
    setUrlQueryObj: setMyClassWeeklyGoalUrlQueryObj,
  } = useMyClassWeeklyGoalFilteringQueryStore();
  const { register, handleSubmit } = useForm();

  const { data: myClassWeeklyGoalData, isFetching } = useQuery(
    ['GET_MY_CLASS_WEEKLY_GOAL_DATA', router.query],
    async () => {
      let queryStringObj;
      if (router.query) {
        queryStringObj = {
          studentIdList: router.query[myClassListKey.STUDENT],
          teacherIdList: router.query[myClassListKey.TEACHER],
          startDateTime: DateTime.fromSeconds(
            Number(
              router.query[CommonListFilteringKey.START_DATETIME] as string,
            ),
          ).toISO(),
          endDateTime: DateTime.fromSeconds(
            Number(router.query[CommonListFilteringKey.END_DATETIME] as string),
          )
            .plus({ day: 1 })
            .toISO(),
          page: router.query.pageNumber ?? null,
          pageSize: PAGE_SIZE,
          search: router.query.searchValue ?? null,
        };
        setMyClassWeeklyGoalUrlQueryObj(router.query);
      }
      const response = await getMyClassWeeklyGoalApi(queryStringObj);
      return response;
    },
    {
      keepPreviousData: true,
      enabled: isRouterHasFilteringQuery(router.query),
    },
  );

  useEffect(() => {
    return () => {
      useMyClassWeeklyGoalFilteringQueryStore.persist.rehydrate();
    };
  }, []);

  const columnData: CommonListColumnData[] = [
    {
      width: '10rem',
      text: '학생',
      id: myClassListKey.STUDENT,
      filterList: filteringStudentList,
      filterType: ListFilter.MULTI_SEARCH,
    },
    {
      width: '30rem',
      text: '미션',
      id: myClassListKey.MISSION_STATUS,
    },
    {
      width: '30rem',
      text: '학습 시간',
      id: myClassListKey.STUDY_TIME_STATUS,
    },
    {
      width: '7.5rem',
      text: '선생님',
      id: myClassListKey.TEACHER,
      filterList: filteringTeacherList,
      filterType: ListFilter.MULTI_SEARCH,
    },
  ];

  const myClassWeeklyGoalDefaultFiltering = {
    [myClassListKey.TEACHER]: user?.id,
    [myClassListKey.STUDENT]: null,
    [CommonListFilteringKey.START_DATETIME]: TODAY_TIMESTAMP,
    [CommonListFilteringKey.END_DATETIME]: TODAY_TIMESTAMP,
  };

  useEffect(() => {
    if (
      user &&
      router.isReady &&
      (router.query?.[MY_CLASS_PATH_QUERY] as object)[0] === 'weekly-goal'
    ) {
      const routerQueryList = Object.keys(router.query);
      if (
        !routerQueryList.includes(CommonListFilteringKey.START_DATETIME) ||
        !routerQueryList.includes(CommonListFilteringKey.END_DATETIME)
      ) {
        const newquery = {};
        for (
          let i = 0;
          i < Object.keys(myClassWeeklyGoalDefaultFiltering).length;
          i++
        ) {
          const filteringKey = Object.keys(myClassWeeklyGoalDefaultFiltering)[
            i
          ];
          if (
            filteringKey === CommonListFilteringKey.START_DATETIME ||
            filteringKey === CommonListFilteringKey.END_DATETIME
          ) {
            newquery[filteringKey] =
              myClassWeeklyGoalDefaultFiltering[filteringKey];
          } else {
            if (myClassWeeklyGoalUrlQueryObj) {
              if (myClassWeeklyGoalUrlQueryObj[filteringKey]) {
                newquery[filteringKey] =
                  myClassWeeklyGoalUrlQueryObj[filteringKey];
              }
            } else {
              newquery[filteringKey] =
                myClassWeeklyGoalDefaultFiltering[filteringKey];
            }
          }
        }
        router.replace({
          pathname: router.asPath,
          query: newquery as ParsedUrlQueryInput,
        });
      }
    }
  }, [user, router.isReady]);

  return (
    <CommonList
      title={'목표 달성 관리'}
      columnData={columnData}
      rowData={myClassWeeklyGoalData ? myClassWeeklyGoalData.tableData : []}
      pageData={
        myClassWeeklyGoalData
          ? { ...myClassWeeklyGoalData.pagination, pageSize: PAGE_SIZE }
          : null
      }
      customColumn={[
        {
          id: myClassListKey.STUDENT,
          type: 'element',
          renderComponent: (rowData) =>
            rowData[myClassListKey.STUDENT] ? (
              <MyClassStudentInfoCell
                student={rowData[myClassListKey.STUDENT]}
              />
            ) : (
              <></>
            ),
        },
        {
          id: myClassListKey.MISSION_STATUS,
          type: 'element',
          renderComponent: (rowData) =>
            rowData[myClassListKey.STUDENT] &&
            rowData[myClassListKey.MISSION_STATUS] ? (
              <MyClassMissionStatusCell
                studentData={rowData[myClassListKey.STUDENT]}
                missionData={rowData[myClassListKey.MISSION_STATUS]}
              />
            ) : (
              <></>
            ),
        },
        {
          id: myClassListKey.STUDY_TIME_STATUS,
          type: 'element',
          renderComponent: (rowData) =>
            rowData[myClassListKey.STUDY_TIME_STATUS] ? (
              <MyClassStudyTimeStatusCell
                studyTimeData={rowData[myClassListKey.STUDY_TIME_STATUS]}
              />
            ) : (
              <></>
            ),
        },
        {
          id: myClassListKey.TEACHER,
          type: 'element',
          renderComponent: (rowData) =>
            rowData[myClassListKey.STUDENT] ? (
              <div>{rowData[myClassListKey.STUDENT]?.teacherName}</div>
            ) : (
              <></>
            ),
        },
      ]}
      searchForm={{
        searchSubmit: handleSubmit,
        searchRegister: register,
        placeholder: '검색',
      }}
      dateCarousel={{
        start: dayjs.unix(
          Number(router.query[CommonListFilteringKey.START_DATETIME] as string),
        ),
        end: dayjs.unix(
          Number(router.query[CommonListFilteringKey.END_DATETIME] as string),
        ),
        calculateMaxDate: ({ startDate }) => {
          if (startDate) {
            return startDate.add(6, 'day');
          }
          return null;
        },
        moveDataButton: {
          content: '오늘',
          onClickEvent: () => {
            router.replace({
              pathname: router.pathname,
              query: {
                ...router.query,
                [CommonListFilteringKey.START_DATETIME]: TODAY_TIMESTAMP,
                [CommonListFilteringKey.END_DATETIME]: TODAY_TIMESTAMP,
              },
            });
          },
        },
      }}
      isLoading={isFetching}
    ></CommonList>
  );
};

export default MyClassWeeklyGoalPage;
