import React from 'react';

const BookIcon = ({ width = '16', height = '16', color = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 16 16`}
      fill="none"
    >
      <path
        d="M4.49984 14.6666C3.99984 14.6666 3.56928 14.4972 3.20817 14.1583C2.84706 13.8194 2.6665 13.4 2.6665 12.9V3.59998C2.6665 3.17776 2.79706 2.79998 3.05817 2.46665C3.31928 2.13332 3.66095 1.92221 4.08317 1.83332L9.08317 0.849984C9.49428 0.761095 9.86095 0.849984 10.1832 1.11665C10.5054 1.38332 10.6665 1.72776 10.6665 2.14998V10.1C10.6665 10.4222 10.5665 10.7083 10.3665 10.9583C10.1665 11.2083 9.91095 11.3611 9.59984 11.4166L4.34984 12.4667C4.24984 12.4889 4.1665 12.5416 4.09984 12.625C4.03317 12.7083 3.99984 12.8 3.99984 12.9C3.99984 13.0222 4.04984 13.125 4.14984 13.2083C4.24984 13.2916 4.3665 13.3333 4.49984 13.3333H11.9998V3.33332C11.9998 3.14443 12.0637 2.98609 12.1915 2.85832C12.3193 2.73054 12.4776 2.66665 12.6665 2.66665C12.8554 2.66665 13.0137 2.73054 13.1415 2.85832C13.2693 2.98609 13.3332 3.14443 13.3332 3.33332V13.3333C13.3332 13.7 13.2026 14.0139 12.9415 14.275C12.6804 14.5361 12.3665 14.6666 11.9998 14.6666H4.49984ZM5.99984 10.7833L9.33317 10.1333V2.16665L5.99984 2.81665V10.7833ZM4.6665 11.05V3.08332L4.4165 3.13332C4.29428 3.15554 4.19428 3.20832 4.1165 3.29165C4.03873 3.37498 3.99984 3.47776 3.99984 3.59998V11.2167C4.05539 11.1944 4.11373 11.175 4.17484 11.1583C4.23595 11.1416 4.29428 11.1278 4.34984 11.1166L4.6665 11.05Z"
        fill={color}
      />
    </svg>
  );
};
export const FilledBookIcon = ({
  width = '16',
  height = '16',
  color = 'white',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 16 16`}
      fill="none"
    >
      <path
        d="M4.49984 14.6666C3.99984 14.6666 3.56928 14.4972 3.20817 14.1583C2.84706 13.8194 2.6665 13.4 2.6665 12.9V3.59998C2.6665 3.17776 2.79706 2.79998 3.05817 2.46665C3.31928 2.13332 3.66095 1.92221 4.08317 1.83332L9.08317 0.849984C9.49428 0.761095 9.86095 0.849984 10.1832 1.11665C10.5054 1.38332 10.6665 1.72776 10.6665 2.14998V10.1C10.6665 10.4222 10.5665 10.7083 10.3665 10.9583C10.1665 11.2083 9.91095 11.3611 9.59984 11.4166L4.34984 12.4667C4.24984 12.4889 4.1665 12.5416 4.09984 12.625C4.03317 12.7083 3.99984 12.8 3.99984 12.9C3.99984 13.0222 4.04984 13.125 4.14984 13.2083C4.24984 13.2916 4.3665 13.3333 4.49984 13.3333H11.9998V3.33332C11.9998 3.14443 12.0637 2.98609 12.1915 2.85832C12.3193 2.73054 12.4776 2.66665 12.6665 2.66665C12.8554 2.66665 13.0137 2.73054 13.1415 2.85832C13.2693 2.98609 13.3332 3.14443 13.3332 3.33332V13.3333C13.3332 13.7 13.2026 14.0139 12.9415 14.275C12.6804 14.5361 12.3665 14.6666 11.9998 14.6666H4.49984ZM5.4665 10.9C5.62206 10.8666 5.74984 10.7889 5.84984 10.6666C5.94984 10.5444 5.99984 10.4055 5.99984 10.25V3.63332C5.99984 3.42221 5.91928 3.24998 5.75817 3.11665C5.59706 2.98332 5.41095 2.93887 5.19984 2.98332C5.04428 3.01665 4.9165 3.09443 4.8165 3.21665C4.7165 3.33887 4.6665 3.47776 4.6665 3.63332V10.25C4.6665 10.4611 4.74706 10.6333 4.90817 10.7667C5.06928 10.9 5.25539 10.9444 5.4665 10.9Z"
        fill={color}
      />
    </svg>
  );
};

export default BookIcon;
