import {
  BasicButtonStyleType,
  IconButtonStyleType,
} from '@asset/type/common/buttonType';
import styled from 'styled-components';
import tw from 'twin.macro';

export const OriginalButtonStyle = styled.div`
  & {
    ${tw`flex items-center justify-center `}

    width: ${(props) => props.sizeAsset.width};
    height: ${(props) => props.sizeAsset.height};

    font-size: 0.875rem;
    font-weight: 700;
    color: ${(props) => props.buttonAsset.textColor};

    background-color: ${(props) => props.buttonAsset.backgroundColor};
    border: 1px solid ${(props) => props.buttonAsset.borderColor ?? 'inherit'};
    border-radius: 0.375rem;
    cursor: ${(props) => props.buttonAsset.cursor};

    &:hover {
      color: ${(props) => props.buttonAsset.hoverTextColor};
      background-color: ${(props) => props.buttonAsset.hoverColor};
      border: 1px solid
        ${(props) => props.buttonAsset.hoverBorderColor ?? 'inherit'};
    }

    input,
    div {
      ${tw`flex items-center justify-center w-full h-full`}
    }

    input {
      ${tw`bg-transparent border-transparent cursor-pointer text-inherit`}
      font-weight: inherit;
    }
  }
`;

export const MoreContentButtonStyle = styled.a`
  ${tw`flex items-center justify-center w-full p-4 border-t border-solid cursor-pointer text-label-lg-700 text-my-gray-40 rounded-b-3xl border-my-gray-80`}
`;

export const BasicButtonStyle = styled.button<BasicButtonStyleType>`
  & {
    min-width: ${(props) => props.width};
    width: ${(props) => props.width};
    display: flex;
    height: ${(props) => props.sizeAsset.height};
    padding-top: ${(props) => props.sizeAsset.paddingY};
    padding-bottom: ${(props) => props.sizeAsset.paddingY};
    padding-left: ${(props) => props.sizeAsset.paddingX};
    padding-right: ${(props) => props.sizeAsset.paddingX};
    justify-content: center;
    align-items: center;

    font-size: ${(props) => props.sizeAsset.fontSize};
    font-weight: ${(props) => props.sizeAsset.fontWeight};
    line-height: ${(props) => props.sizeAsset.lineHeight};
    color: ${(props) =>
      props.isActive
        ? props.colorAsset['contentColor']
        : props.colorAsset['disabledContentColor']};

    border-radius: 0.5rem;
    border: 1px solid
      ${(props) =>
        props.isActive
          ? props.colorAsset['borderColor']
          : props.colorAsset['disabledBorderColor']};
    background: ${(props) =>
      props.isActive
        ? props.colorAsset['backgroundColor']
        : props.colorAsset['disabledBackgroundColor']};
    cursor: ${(props) => (props.isActive ? 'pointer' : 'unset')};
  }
  &:hover {
    background: ${(props) =>
      props.isActive
        ? props.colorAsset['hoverBackgroundColor']
        : props.colorAsset['disabledBackgroundColor']};
  }
  &:active {
    background: ${(props) =>
      props.isActive
        ? props.colorAsset['activeBackgroundColor']
        : props.colorAsset['disabledBackgroundColor']};
  }
`;

export const IconButtonStyle = styled(BasicButtonStyle)<IconButtonStyleType>`
  & {
    gap: 0.25rem;
    width: ${(props) => props.width};
    padding-left: ${(props) =>
      props.leftIcon ? props.sizeAsset.iconPadding : props.sizeAsset.paddingX};
    padding-right: ${(props) =>
      props.rightIcon ? props.sizeAsset.iconPadding : props.sizeAsset.paddingX};
  }
`;

export const MoveDateButtonStyle = styled.button`
  & {
    ${tw`flex justify-end h-6 px-3 py-1 rounded-lg bg-my-gray-100 text-my-gray-30 text-label-sm-700 hover:bg-my-gray-90`}
    border: 1px solid var(--gray-80);
  }
`;
