import { StudyContentPageType } from '@asset/enum/materialEnum';
import { StudyItemType, StudyStep } from '@asset/enum/problemSolvingEnum';
import { TeacherPage } from '@asset/enum/page';
import { UrlObject } from 'url';

export const STUDENT_STUDY_CONTENT_URL_PREFIX =
  '/manage/student-study-content/';
export const MY_STUDY_CONTENT_URL_PREFIX = '/my-study-content/';
export const ASSIGNMENT_RESULT_URL_PREFIX = '/assignment-result/';
export const STUDY_REPORT_ASSIGNMENT_RESULT_URL_PREFIX =
  '/study-report/assignment-result/';
export const STUDY_REPORT_ASSIGNMENT_RESULT_DETAIL_URL_PREFIX =
  '/study-report/assignment-result/detail/';
export const MANAGE_ROADMAP_URL_PREFIX = `/manage/dashboard/${TeacherPage.ROADMAP}/`;

export const getManageStudentStudyContentUrlObject = (
  studyContentType: StudyContentPageType,
  studyContentId: number,
  query?: {
    studentId?: number;
    editingAssignmentId?: number;
    modalType?: 'studentAnswer' | 'material';
    studyItemType?: StudyItemType;
    pageId?: number;
    problemStatusId?: number;
    problemStatusDetailId?: number;
    studyStatusType?: StudyStep;
    pagesIndex?: number;
    isFirst?: boolean;
    isLast?: boolean;
    // mastery list에서 item 클릭 시 해당 item의 정보를 넘겨주기 위한 query
    pickedMyStudyContentId?: number;
    pickedPageType?: StudyContentPageType;
  } | null,
): UrlObject => {
  const urlString = `${STUDENT_STUDY_CONTENT_URL_PREFIX}${studyContentType}/${studyContentId}`;
  return { pathname: urlString, query: query };
};

export const getProblemSolvingUrlObject = (
  studyContentId: number,
  studyContentType: StudyContentPageType,
  pageId: number,
  query?: {
    type?: StudyStep;
    previousPage?: string;
    reportId?: number;
    problemFiltering?: string;
  } | null,
): UrlObject => {
  const urlString = `/problem-solving/${studyContentId}/${studyContentType}/${pageId}`;
  return { pathname: urlString, query: query };
};

export const getMyStudyContentUrlObject = (
  studyContentType: StudyContentPageType,
  studyContentId: number,
  query?: {
    problemFiltering?: string;
    limit?: number;
    offset?: number;
  } | null,
): UrlObject => {
  const urlString = `${MY_STUDY_CONTENT_URL_PREFIX}${studyContentType}/${studyContentId}`;
  return { pathname: urlString, query: query };
};

export const getManageSchedulesUrlObject = (
  studentId: number,
  query: {
    type?: string[];
    startDay?: string;
  } = {},
): UrlObject => {
  const urlString = `/manage/dashboard/${TeacherPage.SCHEDULE}/${studentId}`;
  delete query?.type;
  return { pathname: urlString, query: query };
};

export const getManageRoadmapUrlObject = (studentId: number): UrlObject => {
  const urlString = `${MANAGE_ROADMAP_URL_PREFIX}${studentId}`;
  return { pathname: urlString };
};

export const getAssignmentResultUrlObject = (
  assignmentId: number,
  query?: {
    studentId?: number;
    nextMissionId?: number;
    historyBack?: boolean;
  } | null,
): UrlObject => {
  const urlString = `${ASSIGNMENT_RESULT_URL_PREFIX}${assignmentId}`;
  return { pathname: urlString, query: query };
};

export const getStudyReportAssignmentResultUrlObject = (
  reportId: string,
): UrlObject => {
  const urlString = `${STUDY_REPORT_ASSIGNMENT_RESULT_URL_PREFIX}${reportId}`;
  return { pathname: urlString };
};

export const getStudyReportAssignmentResultDetailUrlObject = (
  reportId: string,
  query: {
    checkedIdData: string;
  },
): UrlObject => {
  const urlString = `${STUDY_REPORT_ASSIGNMENT_RESULT_DETAIL_URL_PREFIX}${reportId}`;
  return { pathname: urlString, query };
};

export const STUDY_CONTENT_REPORT_URL_PREFIX = '/study-content-report/';

export const getStudyContentReportUrlObject = (
  studyContentIds: number | string[],
  query?: {
    checkedIdData: string;
    course?: string;
  },
): UrlObject => {
  const urlString = `${STUDY_CONTENT_REPORT_URL_PREFIX}${studyContentIds}`;
  return { pathname: urlString, query };
};

export const getTestCoverUrlObject = (
  contentId: number,
  pageType: string,
): UrlObject => {
  const urlString = `/test/cover/${contentId}/${pageType}`;
  return { pathname: urlString };
};
