import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import FormDatePickerCalendarHeader from './form/FormDatePickerCalendarHeader';
import dayjs from 'dayjs';
import { FormDatePickerStyle } from '@styles/common/formStyles';
import { ClickAwayListener } from '@mui/material';

interface Props {
  start: Date;
  end: Date;
  today: Date;
  onChangeEvent: (info: { startDate: Date; endDate: Date }) => void;
  focusInput?: 'start' | 'end';
  closeEvent: () => void;
  maxDate?: Date;
}

const DateRangeCalendar = ({
  start,
  end,
  today,
  onChangeEvent,
  focusInput = 'start',
  closeEvent,
  maxDate = null,
}: Props) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setStartDate(start);
  }, [start]);

  useEffect(() => {
    setEndDate(end);
  }, [end]);

  return (
    <ClickAwayListener onClickAway={closeEvent}>
      <div>
        <FormDatePickerStyle
          startDate={startDate ? dayjs(startDate).get('date') : ''}
          endDate={endDate ? dayjs(endDate).get('date') : ''}
          focusInput={focusInput}
        >
          <DatePicker
            fixedHeight
            startDate={startDate}
            endDate={endDate}
            openToDate={today}
            onChange={(dates) => {
              let start = dates[0];
              let end = dates[1];

              if (focusInput) {
                if (focusInput === 'end' && startDate) {
                  if (startDate > start) {
                    start = start;
                    end = null;
                  } else {
                    start = startDate;
                    end = end ? end : start;
                  }
                } else {
                  if (endDate) {
                    if (start > endDate) {
                      start = start;
                      end = null;
                    } else {
                      start = start;
                      end = endDate;
                    }
                  } else {
                    start = start;
                    end = end;
                  }
                }
              }
              setStartDate(start);
              setEndDate(end);
              onChangeEvent &&
                onChangeEvent({
                  startDate: start,
                  endDate: end,
                });
            }}
            selectsRange
            inline
            renderCustomHeader={FormDatePickerCalendarHeader}
            maxDate={maxDate}
          />
        </FormDatePickerStyle>
      </div>
    </ClickAwayListener>
  );
};

export default DateRangeCalendar;
