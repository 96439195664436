import { getUrlByQueryParameterObj } from '@asset/function/queryStringFunctions';
import { authAPI } from '../../core/instance';

export const getMyClassApi = async (queryStringObj) => {
  const queryString = getUrlByQueryParameterObj('', queryStringObj);
  const api = `/api/teachers/classroom/${queryString}`;

  const data = await authAPI
    .get(api)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};

export const getMyClassWeeklyGoalApi = async (queryStringObj) => {
  const queryString = getUrlByQueryParameterObj('', queryStringObj);
  const api = `/api/teachers/goal-management/${queryString}`;

  const data = await authAPI
    .get(api)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return data;
};
