import React, { useCallback, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import * as amplitude from '@amplitude/analytics-browser';
import { useEventPropertiesStore } from '@store/common';
import { useRouter } from 'next/router';
import { LnbStyle } from '@styles/common/lnbStyles';
import { useSelectedStudentInfoQuery } from '@apis/query/user';

interface Props {
  list: {
    href: string;
    text: string;
    pageType: string;
  }[];
}

const Lnb = ({ list }: Props) => {
  const router = useRouter();
  const { properties } = useEventPropertiesStore();
  const { data: selectedStudent } = useSelectedStudentInfoQuery();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const tabsRef = useRef([]);

  const handleLnbMenuClick = (value) => {
    if (value?.pageType) {
      amplitude.track(`click_dashboard_sub_nav_${value?.pageType}`, {
        ...properties,
        pageType: value?.pageType,
        studentId: selectedStudent?.id,
      });
    } else if (value.href.includes('study-status-list')) {
      amplitude.track(
        `click_teacher_${
          value.text === '전체 활동' ? 'total' : 'last'
        }_study_status_list`,
      );
    }
  };

  const setTabPosition = useCallback(() => {
    const currentTab = tabsRef.current[activeTabIndex];
    setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
    setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
  }, [
    tabsRef.current[activeTabIndex],
    tabsRef.current[activeTabIndex]?.clientWidth,
    list,
  ]);

  useEffect(() => {
    setTabPosition();
    window.addEventListener('resize', setTabPosition);
    return () => window.removeEventListener('resize', setTabPosition);
  }, [activeTabIndex, setTabPosition]);

  useEffect(() => {
    list?.map((value, index) => {
      if (value?.href.includes(router.asPath.split('?')[0]))
        setActiveTabIndex(index);
    });
  }, [list]);

  return (
    <LnbStyle>
      <div className="relative">
        <ul>
          {list?.map((value, index) => {
            return (
              <Link key={index} href={value?.href}>
                <a
                  ref={(el) => (tabsRef.current[index] = el)}
                  onClick={() => {
                    if (value) handleLnbMenuClick(value);
                    setActiveTabIndex(index);
                  }}
                  className={`
                  ${
                    value?.href.includes(router.asPath.split('?')[0]) &&
                    'picked'
                  } 
                  text-title-md-700 text-my-gray-60 hover:text-my-gray-50
                ${
                  ['Comment', 'Question', 'Test Result'].includes(value.text)
                    ? 'hidden'
                    : ''
                }
                  `}
                >
                  {value.text}
                </a>
              </Link>
            );
          })}
        </ul>
        <span
          className="absolute bottom-0 block h-[3px] transition-all duration-300 bg-my-gray-10"
          style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
        />
      </div>
    </LnbStyle>
  );
};

export default Lnb;
