import React from 'react';
import { tagAsset } from 'asset/design/tagAsset';

export type TagColor =
  | 'blue'
  | 'light-blue'
  | 'light-yellow'
  | 'light-green'
  | 'light-red'
  | 'light-orange'
  | 'light-purple'
  | 'light-pink'
  | 'light-gray';
interface Props {
  label: string;
  color: TagColor;
  type?: 'Multiple' | 'Isolated';
  onClick?: () => void;
}

const Tag = ({ label, color, type = 'Multiple', onClick = null }: Props) => {
  const tagStyle = tagAsset[color];

  return (
    <>
      <div className="tag" onClick={() => onClick && onClick()}>
        <span className="title text-label-sm-700">{label}</span>
      </div>

      <style jsx>{`
        div.tag {
          display: flex;
          padding: 0.3125rem 0.75rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          width: fit-content;
          height: 1.5rem;
          white-space: nowrap;

          background-color: ${tagStyle.backgroundColor};
          border-radius: ${type === 'Multiple' ? '0.5rem' : '1.25rem'};
          box-sizing: border-box;
          cursor: ${onClick ? 'pointer' : ''};

          span.title {
            color: ${tagStyle.textColor};
          }

          &:hover {
            background-color: ${onClick ? tagStyle.hoverBackgroundColor : ''};
          }
        }
      `}</style>
    </>
  );
};

export default Tag;
