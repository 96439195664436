import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

export function useCheckIsIncorrectBuildIdQuery(options: UseQueryOptions) {
  const _options: UseQueryOptions = {};
  if (options.refetchInterval) {
    _options.refetchInterval = options.refetchInterval;
  }

  return useQuery(
    ['CHECK_IS_INCORRECT_BUILD_ID'],
    async () => {
      const data = await axios.get('/api/build-id').then((response) => {
        return response.data;
      });
      const serverSideBuildId = data.buildId;
      return (
        serverSideBuildId &&
        process.env.BUILD_ID &&
        serverSideBuildId !== process.env.BUILD_ID
      );
    },
    _options,
  );
}
