import React from 'react';

const PersonIcon = ({ width = '16', height = '16', color = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M8.00033 8.00002C7.26699 8.00002 6.63921 7.73891 6.11699 7.21669C5.59477 6.69446 5.33366 6.06669 5.33366 5.33335C5.33366 4.60002 5.59477 3.97224 6.11699 3.45002C6.63921 2.9278 7.26699 2.66669 8.00033 2.66669C8.73366 2.66669 9.36144 2.9278 9.88366 3.45002C10.4059 3.97224 10.667 4.60002 10.667 5.33335C10.667 6.06669 10.4059 6.69446 9.88366 7.21669C9.36144 7.73891 8.73366 8.00002 8.00033 8.00002ZM2.66699 12V11.4667C2.66699 11.0889 2.76421 10.7417 2.95866 10.425C3.1531 10.1084 3.41144 9.86669 3.73366 9.70002C4.42255 9.35558 5.12255 9.09724 5.83366 8.92502C6.54477 8.7528 7.26699 8.66669 8.00033 8.66669C8.73366 8.66669 9.45588 8.7528 10.167 8.92502C10.8781 9.09724 11.5781 9.35558 12.267 9.70002C12.5892 9.86669 12.8475 10.1084 13.042 10.425C13.2364 10.7417 13.3337 11.0889 13.3337 11.4667V12C13.3337 12.3667 13.2031 12.6806 12.942 12.9417C12.6809 13.2028 12.367 13.3334 12.0003 13.3334H4.00033C3.63366 13.3334 3.31977 13.2028 3.05866 12.9417C2.79755 12.6806 2.66699 12.3667 2.66699 12ZM4.00033 12H12.0003V11.4667C12.0003 11.3445 11.9698 11.2334 11.9087 11.1334C11.8475 11.0334 11.767 10.9556 11.667 10.9C11.067 10.6 10.4614 10.375 9.85033 10.225C9.23921 10.075 8.62255 10 8.00033 10C7.3781 10 6.76144 10.075 6.15033 10.225C5.53921 10.375 4.93366 10.6 4.33366 10.9C4.23366 10.9556 4.1531 11.0334 4.09199 11.1334C4.03088 11.2334 4.00033 11.3445 4.00033 11.4667V12ZM8.00033 6.66669C8.36699 6.66669 8.68088 6.53613 8.94199 6.27502C9.2031 6.01391 9.33366 5.70002 9.33366 5.33335C9.33366 4.96669 9.2031 4.6528 8.94199 4.39169C8.68088 4.13058 8.36699 4.00002 8.00033 4.00002C7.63366 4.00002 7.31977 4.13058 7.05866 4.39169C6.79755 4.6528 6.66699 4.96669 6.66699 5.33335C6.66699 5.70002 6.79755 6.01391 7.05866 6.27502C7.31977 6.53613 7.63366 6.66669 8.00033 6.66669Z"
        fill={color}
      />
    </svg>
  );
};
export const FilledPersonIcon = ({
  width = '16',
  height = '16',
  color = 'white',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M8.00008 8.00001C7.26675 8.00001 6.63897 7.73889 6.11675 7.21667C5.59453 6.69445 5.33341 6.06667 5.33341 5.33334C5.33341 4.60001 5.59453 3.97223 6.11675 3.45001C6.63897 2.92778 7.26675 2.66667 8.00008 2.66667C8.73341 2.66667 9.36119 2.92778 9.88341 3.45001C10.4056 3.97223 10.6667 4.60001 10.6667 5.33334C10.6667 6.06667 10.4056 6.69445 9.88341 7.21667C9.36119 7.73889 8.73341 8.00001 8.00008 8.00001ZM2.66675 12V11.4667C2.66675 11.0889 2.76397 10.7417 2.95841 10.425C3.15286 10.1083 3.41119 9.86667 3.73341 9.70001C4.4223 9.35556 5.1223 9.09723 5.83341 8.925C6.54453 8.75278 7.26675 8.66667 8.00008 8.66667C8.73341 8.66667 9.45564 8.75278 10.1667 8.925C10.8779 9.09723 11.5779 9.35556 12.2667 9.70001C12.589 9.86667 12.8473 10.1083 13.0417 10.425C13.2362 10.7417 13.3334 11.0889 13.3334 11.4667V12C13.3334 12.3667 13.2029 12.6806 12.9417 12.9417C12.6806 13.2028 12.3667 13.3333 12.0001 13.3333H4.00008C3.63341 13.3333 3.31953 13.2028 3.05841 12.9417C2.7973 12.6806 2.66675 12.3667 2.66675 12Z"
        fill={color}
      />
    </svg>
  );
};

export default PersonIcon;
