import React from 'react';

const DescendingIcon = ({ width = '16', height = '16' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.3 18.3L8.69998 15.7C8.38331 15.3833 8.31248 15.0208 8.48748 14.6125C8.66248 14.2042 8.97498 14 9.42498 14H14.575C15.025 14 15.3375 14.2042 15.5125 14.6125C15.6875 15.0208 15.6166 15.3833 15.3 15.7L12.7 18.3C12.6 18.4 12.4916 18.475 12.375 18.525C12.2583 18.575 12.1333 18.6 12 18.6C11.8666 18.6 11.7416 18.575 11.625 18.525C11.5083 18.475 11.4 18.4 11.3 18.3Z"
        fill="#192029"
      />
      <path
        d="M9.42498 10C8.97498 10 8.66248 9.79584 8.48748 9.3875C8.31248 8.97917 8.38331 8.61667 8.69998 8.3L11.3 5.7C11.4 5.6 11.5083 5.525 11.625 5.475C11.7416 5.425 11.8666 5.4 12 5.4C12.1333 5.4 12.2583 5.425 12.375 5.475C12.4916 5.525 12.6 5.6 12.7 5.7L15.3 8.3C15.6166 8.61667 15.6875 8.97917 15.5125 9.3875C15.3375 9.79584 15.025 10 14.575 10H9.42498Z"
        fill="#A8B2BE"
      />
    </svg>
  );
};

export default DescendingIcon;
