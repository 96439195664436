import { UrlObject } from 'url';

export enum StudyReportSlide {
  STUDY_TIME = 'study-time',
  STUDY_RECORDS = 'study-records',
  COURSE_DETAIL = 'course-detail',
  TEST_RESULTS = 'test-results',
}

export const getStudyReportSlideUrlObject = (
  reportType: 'daily' | 'weekly',
  reportSlide: StudyReportSlide,
  query: {
    reportId: string;
    recipientId?: string;
    courseId?: number;
  } | null,
): UrlObject => {
  const urlString = `/v2/study-report/${reportType}/${reportSlide}/`;
  return { pathname: urlString, query: query };
};

export const getStudyReportUrlObject = (
  reportId: string,
  query: {
    recipientId?: string;
  } | null,
): UrlObject => {
  const urlString = `/v2/study-report/${reportId}/`;
  return { pathname: urlString, query: query };
};

export const getParentStudyReportUrlObject = (
  reportId: string,
  query: {
    recipientId?: string;
  } | null,
): UrlObject => {
  const urlString = `/v2/study-report/parent/${reportId}/`;
  return { pathname: urlString, query: query };
};

export const getStudentStudyReportUrlObject = (
  reportId: string,
  query: {
    recipientId?: string;
  } | null,
): UrlObject => {
  const urlString = `/v2/study-report/student/${reportId}/`;
  return { pathname: urlString, query: query };
};
