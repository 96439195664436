import React from 'react';

const RefreshIcon = ({ width = '16', height = '16', color = '#192029' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.00008 13.3337C6.51119 13.3337 5.25008 12.817 4.21675 11.7837C3.18341 10.7503 2.66675 9.48921 2.66675 8.00033C2.66675 6.51144 3.18341 5.25033 4.21675 4.21699C5.25008 3.18366 6.51119 2.66699 8.00008 2.66699C8.76675 2.66699 9.50008 2.82533 10.2001 3.14199C10.9001 3.45866 11.5001 3.91144 12.0001 4.50033V3.33366C12.0001 3.14477 12.064 2.98644 12.1917 2.85866C12.3195 2.73088 12.4779 2.66699 12.6667 2.66699C12.8556 2.66699 13.014 2.73088 13.1417 2.85866C13.2695 2.98644 13.3334 3.14477 13.3334 3.33366V6.66699C13.3334 6.85588 13.2695 7.01421 13.1417 7.14199C13.014 7.26977 12.8556 7.33366 12.6667 7.33366H9.33341C9.14453 7.33366 8.98619 7.26977 8.85841 7.14199C8.73064 7.01421 8.66675 6.85588 8.66675 6.66699C8.66675 6.4781 8.73064 6.31977 8.85841 6.19199C8.98619 6.06421 9.14453 6.00033 9.33341 6.00033H11.4667C11.1112 5.3781 10.6251 4.88921 10.0084 4.53366C9.39175 4.1781 8.7223 4.00033 8.00008 4.00033C6.88897 4.00033 5.94453 4.38921 5.16675 5.16699C4.38897 5.94477 4.00008 6.88921 4.00008 8.00033C4.00008 9.11144 4.38897 10.0559 5.16675 10.8337C5.94453 11.6114 6.88897 12.0003 8.00008 12.0003C8.75564 12.0003 9.4473 11.8087 10.0751 11.4253C10.7029 11.042 11.189 10.5281 11.5334 9.88366C11.6223 9.7281 11.7473 9.61977 11.9084 9.55866C12.0695 9.49755 12.2334 9.49477 12.4001 9.55032C12.5779 9.60588 12.7056 9.72255 12.7834 9.90033C12.8612 10.0781 12.8556 10.2448 12.7667 10.4003C12.3112 11.2892 11.6612 12.0003 10.8167 12.5337C9.9723 13.067 9.03341 13.3337 8.00008 13.3337Z"
        fill={color}
      />
    </svg>
  );
};

export default RefreshIcon;
