import styled from 'styled-components';
import tw from 'twin.macro';

export const GnbStyles = styled.nav`
  & {
    ${tw`items-center justify-between w-full px-6`}
    display:  ${(props) => (props.userType === 'student' ? 'grid' : 'flex')};
    grid-template-columns: 1fr 3fr 1fr;
    height: var(--height-main-nav);
    background-color: var(--gray-20);
    border-bottom: 1px solid var(--gray-30);
    z-index: 900;

    ul.menu-box {
      ${tw`flex m-0`}
      gap: ${(props) => (props.userType === 'student' ? '1rem' : '1.5rem')};
      flex: 1;
      justify-content: ${(props) =>
        props.userType === 'student' ? 'center' : 'start'};
      > a {
        ${tw`flex items-center gap-2 px-[0.625rem] py-5 text-label-lg-700 text-my-gray-60`}

        &:hover {
          color: var(--gray-100);
          svg {
            > path {
              fill: var(--gray-100);
            }
          }
        }

        &.picked {
          ${tw`text-label-lg-700 text-my-gray-100`}
          svg {
            > path {
              fill: var(--gray-100);
            }
          }
        }
      }
    }

    > div.logo-box {
      ${tw`flex items-center justify-start gap-12 mr-12`}

      .object-container {
        width: 11.125rem;
        position: relative;
        a {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 5;
          top: 0px;
          left: 0px;
        }
        object {
          width: 100%;
        }
      }

      > span {
        color: var(--old-navy);
      }
    }
  }
  div.account-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    position: relative;

    .gnb-icon {
      cursor: pointer;
    }
    .student-profile {
      ${tw`relative flex`}
    }
  }
`;
export const NotificationBoxStyles = styled.div`
  & {
    display: flex;
    width: 100%;
    padding: 0.5rem 1rem;
    min-height: 3.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background: #f64857;
    font-size: 0.875rem;
    color: white;
    font-weight: 500;
    position: relative;
    transition-duration: 500ms;
    white-space: break-spaces;
    text-align: center;
    display: ${(props) => (props.hidden ? 'none' : '')};
    &:after {
      content: '';
      position: absolute;
      top: 1px;
      right: 5.9%;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-bottom-color: #f64857;
      border-top: 0;
      margin-left: -7px;
      margin-top: -7px;
      z-index: 900;
    }
  }
`;
