import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';

const MyClassStudentInfoCell = ({ student }) => {
  const router = useRouter();

  return (
    <div
      className={`flex gap-2 items-start cursor-pointer`}
      onClick={() => router.push(`/manage/dashboard/main/${student.studentId}`)}
    >
      <div className={`w-6 h-6 rounded-[50%] overflow-hidden`}>
        <Image
          width={24}
          height={24}
          src={
            student.studentProfileImageSrc
              ? student.studentProfileImageSrc
              : '/common/profile.svg'
          }
        ></Image>
      </div>
      <div className={`flex flex-col gap-1 w-24`}>
        <div
          className={`text-label-sm-700 text-my-gray-10 whitespace-nowrap text-ellipsis overflow-hidden break-all`}
        >
          {student.studentName}
        </div>
        <div
          className={`text-label-sm-600 text-my-gray-50 whitespace-nowrap text-ellipsis overflow-hidden break-all`}
        >
          {student.studentGrade !== '' ? student.studentGrade : '-'}/
          {student.studentSchool ?? '-'}
        </div>
      </div>
    </div>
  );
};

export default MyClassStudentInfoCell;
