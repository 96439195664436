export const ExpandMoreIcon = ({
  width = '16',
  height = '16',
  color = 'var(--gray-10)',
  classes = '',
  onClick = null,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={`${onClick ? 'cursor-pointer' : ''} ${classes}`}
      onClick={onClick}
    >
      <path
        d="M12 16.05C11.8667 16.05 11.7417 16.0292 11.625 15.9875C11.5083 15.9458 11.4 15.875 11.3 15.775L6.69999 11.175C6.51665 10.9917 6.42499 10.7583 6.42499 10.475C6.42499 10.1917 6.51665 9.95833 6.69999 9.775C6.88332 9.59167 7.11665 9.5 7.39999 9.5C7.68332 9.5 7.91665 9.59167 8.09999 9.775L12 13.675L15.9 9.775C16.0833 9.59167 16.3167 9.5 16.6 9.5C16.8833 9.5 17.1167 9.59167 17.3 9.775C17.4833 9.95833 17.575 10.1917 17.575 10.475C17.575 10.7583 17.4833 10.9917 17.3 11.175L12.7 15.775C12.6 15.875 12.4917 15.9458 12.375 15.9875C12.2583 16.0292 12.1333 16.05 12 16.05Z"
        fill={color}
      />
    </svg>
  );
};

export const ExpandLessIcon = ({
  width = '16',
  height = '16',
  color = 'var(--gray-10)',
  classes = '',
  onClick = null,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={`${onClick ? 'cursor-pointer' : ''} ${classes}`}
      onClick={onClick}
    >
      <path
        d="M12 10.325L8.09999 14.225C7.91665 14.4083 7.68332 14.5 7.39999 14.5C7.11665 14.5 6.88332 14.4083 6.69999 14.225C6.51665 14.0417 6.42499 13.8083 6.42499 13.525C6.42499 13.2417 6.51665 13.0083 6.69999 12.825L11.3 8.225C11.4 8.125 11.5083 8.05416 11.625 8.0125C11.7417 7.97083 11.8667 7.95 12 7.95C12.1333 7.95 12.2583 7.97083 12.375 8.0125C12.4917 8.05416 12.6 8.125 12.7 8.225L17.3 12.825C17.4833 13.0083 17.575 13.2417 17.575 13.525C17.575 13.8083 17.4833 14.0417 17.3 14.225C17.1167 14.4083 16.8833 14.5 16.6 14.5C16.3167 14.5 16.0833 14.4083 15.9 14.225L12 10.325Z"
        fill={color}
      />
    </svg>
  );
};
