import React from 'react';

const CalendarTodayIcon = ({
  width = '16',
  height = '16',
  color = 'white',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M3.33333 14.6667C2.96667 14.6667 2.65278 14.5361 2.39167 14.275C2.13056 14.0139 2 13.7 2 13.3333V4.00001C2 3.63334 2.13056 3.31945 2.39167 3.05834C2.65278 2.79723 2.96667 2.66668 3.33333 2.66668H4V2.00001C4 1.81112 4.06389 1.65279 4.19167 1.52501C4.31944 1.39723 4.47778 1.33334 4.66667 1.33334C4.85556 1.33334 5.01389 1.39723 5.14167 1.52501C5.26944 1.65279 5.33333 1.81112 5.33333 2.00001V2.66668H10.6667V2.00001C10.6667 1.81112 10.7306 1.65279 10.8583 1.52501C10.9861 1.39723 11.1444 1.33334 11.3333 1.33334C11.5222 1.33334 11.6806 1.39723 11.8083 1.52501C11.9361 1.65279 12 1.81112 12 2.00001V2.66668H12.6667C13.0333 2.66668 13.3472 2.79723 13.6083 3.05834C13.8694 3.31945 14 3.63334 14 4.00001V13.3333C14 13.7 13.8694 14.0139 13.6083 14.275C13.3472 14.5361 13.0333 14.6667 12.6667 14.6667H3.33333ZM3.33333 13.3333H12.6667V6.66668H3.33333V13.3333ZM3.33333 5.33334H12.6667V4.00001H3.33333V5.33334Z"
        fill={color}
      />
    </svg>
  );
};
export const FilledCalendarTodayIcon = ({
  width = '16',
  height = '16',
  color = 'white',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M3.83333 14.6663C3.46667 14.6663 3.15278 14.5358 2.89167 14.2747C2.63056 14.0136 2.5 13.6997 2.5 13.333V3.99967C2.5 3.63301 2.63056 3.31912 2.89167 3.05801C3.15278 2.7969 3.46667 2.66634 3.83333 2.66634H4.5V1.99967C4.5 1.81079 4.56389 1.65245 4.69167 1.52467C4.81944 1.3969 4.97778 1.33301 5.16667 1.33301C5.35556 1.33301 5.51389 1.3969 5.64167 1.52467C5.76944 1.65245 5.83333 1.81079 5.83333 1.99967V2.66634H11.1667V1.99967C11.1667 1.81079 11.2306 1.65245 11.3583 1.52467C11.4861 1.3969 11.6444 1.33301 11.8333 1.33301C12.0222 1.33301 12.1806 1.3969 12.3083 1.52467C12.4361 1.65245 12.5 1.81079 12.5 1.99967V2.66634H13.1667C13.5333 2.66634 13.8472 2.7969 14.1083 3.05801C14.3694 3.31912 14.5 3.63301 14.5 3.99967V13.333C14.5 13.6997 14.3694 14.0136 14.1083 14.2747C13.8472 14.5358 13.5333 14.6663 13.1667 14.6663H3.83333ZM3.83333 13.333H13.1667V6.66634H3.83333V13.333Z"
        fill={color}
      />
    </svg>
  );
};

export default CalendarTodayIcon;
