import React from 'react';

const CancelFillIcon = ({
  color = 'var(--red-50)',
  width = '24',
  height = '24',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 13.275L14.9 16.175C15.0834 16.3583 15.2959 16.4458 15.5375 16.4375C15.7792 16.4292 15.9917 16.3333 16.175 16.15C16.3584 15.9667 16.45 15.7542 16.45 15.5125C16.45 15.2708 16.3584 15.0587 16.175 14.8761L13.275 11.9875L16.175 9.09892C16.3584 8.9163 16.45 8.70416 16.45 8.46249C16.45 8.22083 16.3584 8.00833 16.175 7.82499C15.9917 7.64166 15.7792 7.54999 15.5375 7.54999C15.2959 7.54999 15.0834 7.64166 14.9 7.82499L12 10.725L9.10002 7.82499C8.91669 7.64166 8.70836 7.54999 8.47502 7.54999C8.24169 7.54999 8.03336 7.64166 7.85002 7.82499C7.66669 8.00833 7.57502 8.22083 7.57502 8.46249C7.57502 8.70416 7.66597 8.91666 7.84785 9.09999L10.725 12L7.82502 14.9C7.64169 15.0833 7.55419 15.2917 7.56252 15.525C7.57086 15.7583 7.66669 15.9667 7.85002 16.15C8.03336 16.3333 8.24586 16.425 8.48752 16.425C8.72919 16.425 8.94096 16.3341 9.12285 16.1522L12 13.275ZM12.0069 21.6C10.6857 21.6 9.44169 21.35 8.27502 20.85C7.10836 20.35 6.08752 19.6625 5.21252 18.7875C4.33752 17.9125 3.65002 16.892 3.15002 15.726C2.65002 14.5601 2.40002 13.3142 2.40002 11.9885C2.40002 10.6628 2.65002 9.42083 3.15002 8.26249C3.65002 7.10416 4.33752 6.08749 5.21252 5.21249C6.08752 4.33749 7.10801 3.64999 8.27397 3.14999C9.43996 2.64999 10.6858 2.39999 12.0115 2.39999C13.3372 2.39999 14.5792 2.64999 15.7375 3.14999C16.8959 3.64999 17.9125 4.33749 18.7875 5.21249C19.6625 6.08749 20.35 7.10603 20.85 8.26809C21.35 9.43018 21.6 10.6718 21.6 11.9931C21.6 13.3144 21.35 14.5583 20.85 15.725C20.35 16.8917 19.6625 17.9125 18.7875 18.7875C17.9125 19.6625 16.894 20.35 15.7319 20.85C14.5698 21.35 13.3282 21.6 12.0069 21.6Z"
        fill={color}
      />
    </svg>
  );
};

export default CancelFillIcon;
