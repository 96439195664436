import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface EventProperties {
  properties: any;
  setProperties: (value) => void;
}
export const useEventPropertiesStore = create<EventProperties>((set) => ({
  properties: { url: '', path: '' },
  setProperties: (value) => set({ properties: value }),
}));

export enum ScrollComponentKey {
  STUDY_SCHEDULE = 'studySchedule',
  PLAN_GANTT_CHART = 'planGanttChart',
}
type ScrollCoordinate = {
  x: number | null;
  y: number | null;
};
interface ScrollPosition {
  [ScrollComponentKey.STUDY_SCHEDULE]: ScrollCoordinate;
  [ScrollComponentKey.PLAN_GANTT_CHART]: { dateTimestamp: number };
}

interface ScrollPositionStore {
  scrollPosition: ScrollPosition;
  setScrollPosition: (
    key: ScrollComponentKey,
    value: ScrollCoordinate | { dateTimestamp: number },
  ) => void;
  resetScrollPosition: () => void;
}

export const useScrollPositionStore = create(
  persist<ScrollPositionStore>(
    (set) => ({
      scrollPosition: null,
      setScrollPosition: (
        key: ScrollComponentKey,
        value: ScrollCoordinate | { dateTimestamp: number },
      ) =>
        set((state) => ({
          scrollPosition: { ...state.scrollPosition, [key]: value },
        })),
      resetScrollPosition: () =>
        set({
          scrollPosition: null,
        }),
    }),
    {
      name: 'scroll-position-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
