import { MenuBoxStyle } from '@styles/common/box/menuBoxStyles';
import React from 'react';

const SelectFilterBox = ({
  filterValueList,
  onClickEvent,
  top = '3.5rem',
  right = '0',
  width = '7.5rem',
}) => {
  return (
    <MenuBoxStyle position="absolute" top={top} right={right} width={width}>
      {filterValueList?.map((obj, index) => {
        return (
          <span key={index} onClick={() => onClickEvent(obj)}>
            {obj.name}
          </span>
        );
      })}
    </MenuBoxStyle>
  );
};

export default SelectFilterBox;
