import React, { useEffect } from 'react';
import { useSelect } from 'downshift';
import { ExpandMoreIcon, ExpandLessIcon } from '@asset/svg/Expand';

export type DropDownProps = {
  filterType: string | JSX.Element;
  dropDownMenu: {
    title?: string;
    name?: string;
    enum?: string;
  }[];
  onSelect?;
  icons?: { open; close };
};

const BasicDropDown = ({
  filterType,
  dropDownMenu,
  onSelect,
  icons,
}: DropDownProps) => {
  function Select() {
    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getMenuProps,
      getItemProps,
    } = useSelect({
      items: dropDownMenu ?? [],
    });

    const headerArrowObj = {
      open:
        icons && icons.open ? (
          icons.open
        ) : (
          <ExpandLessIcon width={'24'} height={'24'}></ExpandLessIcon>
        ),
      close:
        icons && icons.close ? (
          icons.close
        ) : (
          <ExpandMoreIcon width={'24'} height={'24'}></ExpandMoreIcon>
        ),
    };

    useEffect(() => {
      if (onSelect && selectedItem) {
        onSelect(selectedItem);
      }
    }, [selectedItem]);

    return (
      <>
        <div>
          <button
            aria-label="toggle menu"
            type="button"
            {...getToggleButtonProps()}
          >
            <span>
              {selectedItem
                ? selectedItem.title
                  ? selectedItem.title
                  : selectedItem.name
                  ? selectedItem.name
                  : filterType
                : filterType}
            </span>
            {headerArrowObj && (
              <span>{isOpen ? headerArrowObj.open : headerArrowObj.close}</span>
            )}
          </button>
        </div>

        <ul {...getMenuProps()} className={!isOpen ? 'hidden' : ''}>
          {isOpen &&
            dropDownMenu.map((item, index) => (
              <li
                key={index}
                {...getItemProps({
                  item,
                  index,
                })}
              >
                <span>{item.title ? item.title : item.name}</span>
              </li>
            ))}
        </ul>
      </>
    );
  }
  return <Select />;
};

export default BasicDropDown;
