import axios from 'axios';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/nextjs';
import { getUrlByQueryParameterObj } from '@asset/function/queryStringFunctions';
import { getLoginPageUrlObject } from 'routes/account';
import _ from 'lodash';
import { UrlObject } from 'url';

declare module 'axios' {
  export interface AxiosRequestConfig {
    loginUrl?: UrlObject;
    redirectUrl401?: UrlObject;
    redirectUrl403?: UrlObject;
  }
}

const authAPI = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_ENV_HOST}`,
  withCredentials: true,
});

authAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const {
      method,
      url,
      params,
      data: requestData,
      headers,
      loginUrl,
      redirectUrl401,
      redirectUrl403,
    } = error.config; // axios의 error객체
    const { data: responseData, status } = error.response; // axios의 error객체

    const getLoginUrlPath = (loginUrl: UrlObject) => {
      const { pathname, query } = loginUrl;
      return pathname + getUrlByQueryParameterObj('', query);
    };
    const redirectToLoginPage = () => {
      window.location.replace(
        !_.isNil(loginUrl)
          ? getLoginUrlPath(loginUrl)
          : getLoginUrlPath(
              getLoginPageUrlObject({
                redirectUrl: window.location.pathname + window.location.search,
              }),
            ),
      );
    };

    const redirectTo401Page = !_.isNil(redirectUrl401)
      ? () => window.location.replace(getLoginUrlPath(redirectUrl401))
      : redirectToLoginPage;

    const redirectTo403Page = !_.isNil(redirectUrl403)
      ? () => window.location.replace(getLoginUrlPath(redirectUrl403))
      : redirectToLoginPage;

    if (Math.floor(status / 100) === 4) {
      if (status === 401) {
        if (
          url !== '/api/users/token/refresh/' &&
          url !== '/api/users/token/blacklist/' &&
          (responseData.message === 'ACCESS_TOKEN_EXPIRED' ||
            responseData.message === 'ACCESS_TOKEN_NOT_FOUND')
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          const { data } = await authAPI.post(
            '/api/users/token/refresh/',
            null,
            { loginUrl, redirectUrl401, redirectUrl403 },
          );
          const { access } = data;

          originalRequest.headers['Authorization'] = `Bearer ${access}`;
          authAPI.defaults.headers.common['Authorization'] = `Bearer ${access}`;

          return authAPI(originalRequest);
        } else if (responseData.code === 'LOGIN_FAILED') {
          toast.error('이메일 혹은 비밀번호를 확인해주세요.');
          return Promise.reject(error);
        } else {
          redirectTo401Page();
          localStorage.removeItem('persistAtom');
          return Promise.reject(error);
        }
      }
      if (status === 403) {
        redirectTo403Page();
        return Promise.reject(error);
      }

      if (status === 409) {
        return Promise.reject(error);
      }
    }

    if (Math.floor(status / 100) === 5) {
      toast.error('요청을 처리할 수 없습니다. 관리자에게 문의하세요.');
    }

    Sentry.setContext('API Request Detail', {
      method,
      url,
      params,
      requestData,
      headers,
    });
    Sentry.setContext('API Response Detail', {
      status,
      responseData,
    });

    return Promise.reject(error);
  },
);

export { authAPI };
