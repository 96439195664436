interface Size {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl?: number;
  xxl?: number;
}

export const breakPoints: Size = {
  xs: 320, // for small screen mobile
  sm: 480, // for mobile screen
  md: 640, // for tablets
  lg: 1280, // for laptops
};

export const deviceSize = {
  xs: `(max-width: ${breakPoints.xs}px)`,
  sm: `(max-width: ${breakPoints.sm}px)`,
  md: `(max-width: ${breakPoints.md}px)`,
  lg: `(max-width: ${breakPoints.lg}px)`,
};
