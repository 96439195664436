import { useRouter } from 'next/router';
import React from 'react';
import LineGraphWithText from '@components/common/graph/LineGraphWithText';
import { getManageStudentStudyContentUrlObject } from 'routes/studyContent';
import { StudyContentType } from '@asset/enum/materialEnum';

const MyClassMissionStatusCell = ({ studentData, missionData }) => {
  const router = useRouter();
  if (missionData && missionData.length > 0) {
    const graphData = [];
    missionData.map((mission) => {
      const tooltipTitle = `${mission.subTitle} (${mission.completedStudyItemCount}/${mission.totalStudyItemCount})`;
      if (mission.totalStudyItemCount) {
        graphData.push({
          id: mission.id,
          total: mission.totalStudyItemCount,
          value: mission.completedStudyItemCount + mission.waitProblemCount,
          tooltipTitle: tooltipTitle,
          onClickEvent: () =>
            router.push(
              getManageStudentStudyContentUrlObject(
                StudyContentType.MY_ASSIGNMENT,
                mission.id,
                {
                  studentId: studentData.studentId,
                },
              ),
            ),
        });
      }
    });
    return (
      <LineGraphWithText
        textFormat={['', 'percent']}
        graphData={graphData}
        graphColorCriteriaTable={{
          0: 'var(--blue-50)',
        }}
        graphDomTotalWidth={30}
      ></LineGraphWithText>
    );
  } else {
    return (
      <div
        className={`w-full flex items-center text-label-sm-600 text-my-gray-30 justify-start`}
      >
        미션 없음
      </div>
    );
  }
};

export default MyClassMissionStatusCell;
