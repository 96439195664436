import { ListTableStyle } from '@styles/manage/ListTableStyles';
import React, { Fragment, useEffect, useState } from 'react';
import { ListFilterDropDown } from '../dropDown/ListFilterDropDown';
import { ListDropdownFilterStyle } from '@styles/common/dropDown/dropDownStyles';
import BasicDropDown from '../dropDown/BasicDropDown';
import { timeStampToDateTime } from '@asset/function/commonFunctions';
import { useRouter } from 'next/router';
import MultiSearchFilterBox from '../MultiSearchFilterBox';
import * as amplitude from '@amplitude/analytics-browser';
import SearchInput from '../input/SearchInput';
import BasicPagination from '../BasicPagination';
import BasicButton from '../button/BasicButton';
import {
  ArrangeIcon,
  ArrowDropDownIcon,
  ArrowDropUpIcon,
} from '@asset/svg/ArrowDropIcon';
import AscendingIcon from '@asset/svg/AscendingIcon';
import DescendingIcon from '@asset/svg/DescendingIcon';
import SelectFilterBox from '../SelectFilterBox';
import IconButton from '../button/IconButton';
import AddIcon from '@asset/svg/AddIcon';
import {
  BasicButtonAssetType,
  BasicButtonColor,
  BasicButtonSize,
} from '@asset/type/common/buttonType';
import { encodeQueryValue } from '@asset/function/queryStringFunctions';
import DateCarousel from '../carousel/DateCarousel';
import dayjs, { Dayjs } from 'dayjs';
import { MoveDateButtonStyle } from '@styles/common/button/buttonStyles';
import DateRangeCalendar from '../DateRangeCalendar';
import { getEndOfISOWeek } from '@asset/function/dateFunctions';
import MoreIcon from '@asset/svg/MoreIcon';
import _ from 'lodash';
import LoadingSpinner from '../loading/LoadingSpinner';
import BasicMenu from './BasicMenu';

export enum CommonListFilteringKey {
  START_DATETIME = 'startDateTime',
  END_DATETIME = 'endDateTime',
  SEARCH_VALUE = 'searchValue',
  PAGE_NUMBER = 'pageNumber',
}

type CustomColumnType = {
  id: string;
  type: 'buttonList' | 'timestamp' | 'dropdown' | 'element';
  // type이 buttonList일 때 필요한 요소
  buttonList?: {
    getRoleOfButton: (rowData) => string;
    buttonInfoObj: {
      [key: string]: {
        kind: 'original' | 'element';
        text?: string;
        color?: BasicButtonColor;
        size?: BasicButtonSize;
        active?: boolean;
        width?: string;
        type?: BasicButtonAssetType;
        onClickEvent?: ({ rowData }) => void;
        renderComponent?: ({ rowData }) => JSX.Element;
      };
    };
  }[];
  buttonBoxStyle?: string;
  // type이 dropdown일 때 필요한 요소
  dropdownProps?;
  // type이 timestamp일 때 필요한 요소
  getTimezoneFunction?;
  renderComponent?;
}[];

type ButtonInfoType = {
  type?: 'basic' | 'icon';
  content;
  onClick: (data?) => void;
  disabled?;
  icon?;
};

export enum ListFilter {
  MULTI_SEARCH = 'multiSearch',
  SELECT = 'select',
  SORT = 'sort',
}

export interface CommonListColumnData {
  width: string;
  text: string;
  id: string;
  filterList?: { id: string; name: string }[];
  filterType?: ListFilter;
  function?;
  sort?;
}

interface PropsInterface {
  title?: string;
  columnData: CommonListColumnData[];
  rowData;
  customColumn?: CustomColumnType;
  pageData?: {
    totalCount: number;
    nextPage: boolean;
    previousPage: boolean;
    pageSize?: number;
  };
  filterDropDownProps?;
  searchForm?: {
    searchSubmit;
    searchRegister;
    placeholder: string;
  };
  buttonInfo?: ButtonInfoType;
  dateCarousel?: {
    start: Dayjs;
    end: Dayjs;
    calculateMaxDate?: (dateInfo: {
      startDate: Dayjs;
      endDate: Dayjs;
    }) => Dayjs;
    moveDataButton: {
      content: string;
      onClickEvent: () => void;
    };
  };
  additionalFeature?: {
    routerKey: string;
    header: {
      menuTitle?: string;
      getMenus?: () => {
        id: string | number;
        content: JSX.Element;
      }[];
      onSelect?;
    };
    body: {
      menuTitle?: string;
      getMenus?: (row) => { id: string | number; content: JSX.Element }[];
      onSelect?;
    };
  };
  clickRow?: {
    targetIdList?: string[];
    onClick: (data) => void;
  };
  isLoading?: boolean;
}

const AdditionalFeature = ({
  menuTitle = null,
  menus,
  onSelect,
  rowItem = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="relative">
      <button
        className={`!w-8 h-8 rounded-lg hover:bg-my-gray-90 cursor-pointer flex justify-center items-center`}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <MoreIcon />
      </button>
      {isOpen && (
        <BasicMenu
          menuTitle={menuTitle}
          items={menus}
          onClickAway={() => setIsOpen(false)}
          onClickItem={(selectedItem) => {
            onSelect({ rowItem, selectedItem: selectedItem });
            setIsOpen(false);
          }}
        />
      )}
    </section>
  );
};

const CommonList = ({
  title = null,
  columnData,
  customColumn = [],
  rowData,
  pageData = null,
  filterDropDownProps = null,
  searchForm = null,
  buttonInfo = null,
  dateCarousel = null,
  additionalFeature = null,
  clickRow = null,
  isLoading = false,
}: PropsInterface) => {
  const router = useRouter();
  const filterDropDownMenuObj = [{ title: '전체', enum: '' }];
  filterDropDownProps?.dropDownMenu.map((value) => {
    filterDropDownMenuObj.push({
      title: value.title ?? value,
      enum: value.enum ?? value,
    });
  });
  const [openFilter, setOpenFilter] = useState(null);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [tempStartDate, setTempStartDate] = useState<Dayjs>(null);

  const [tempQueryInRouter, setTempQueryInRouter] = useState(null);
  const [debouncedNewQuery, setDebouncedNewQuery] = useState(null);

  const DEBOUNCE_TIME = 700;

  const recordQueryInRouter = (newQuery) => {
    router.replace(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { scroll: false },
    );
  };

  const renderEmptyTable = () => {
    const result = [];
    for (let i = 0; i < 8; i++) {
      if (i === 3) {
        result.push(
          <tr key={i}>
            <td colSpan={columnData.length + 1}>표시할 정보가 없습니다.</td>
          </tr>,
        );
      } else {
        result.push(
          <tr key={i}>
            <td colSpan={columnData.length + 1}></td>
          </tr>,
        );
      }
    }
    return result;
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedNewQuery(tempQueryInRouter);
    }, DEBOUNCE_TIME);
    return () => clearTimeout(debounce);
  }, [tempQueryInRouter]);

  useEffect(() => {
    if (!_.isNil(debouncedNewQuery)) {
      recordQueryInRouter(debouncedNewQuery);
      setTempQueryInRouter(null);
    }
  }, [debouncedNewQuery]);

  const handleOnClickFilteringButton = (value) => {
    const queryInRouter = tempQueryInRouter ? tempQueryInRouter : router.query;
    if (value.filterType) {
      if (
        value.filterType === ListFilter.MULTI_SEARCH ||
        value.filterType === ListFilter.SELECT
      ) {
        setOpenFilter(openFilter ? '' : value.id);
      } else if (value.filterType === ListFilter.SORT) {
        const sortingTypes = ['desc', 'asc', null];
        const _newQuery = {
          ...queryInRouter,
        };
        if (
          queryInRouter &&
          queryInRouter[CommonListFilteringKey.PAGE_NUMBER] &&
          Number(queryInRouter[CommonListFilteringKey.PAGE_NUMBER]) > 1
        ) {
          _newQuery[CommonListFilteringKey.PAGE_NUMBER] = String(1);
        }
        if (!queryInRouter[value.id]) {
          _newQuery[value.id] = sortingTypes[0];
        } else {
          const nextType =
            sortingTypes[
              sortingTypes.indexOf(queryInRouter[value.id] as string) + 1
            ];
          if (nextType) {
            _newQuery[value.id] = nextType;
          } else {
            delete _newQuery[value.id];
          }
        }
        setTempQueryInRouter(_newQuery);

        const pageType = router.pathname.split('/')[2].replaceAll('-', '_');
        amplitude.track(`click_teacher_${pageType}_filter_solvedAt`, {
          value: router.query[value.id],
        });
      }
    }
  };

  const searchFormSubmit = searchForm
    ? searchForm.searchSubmit(async (data) => {
        let newQuery = { ...router.query };
        if (data.search)
          newQuery = {
            ...router.query,
            pageNumber: '1',
            searchValue: encodeQueryValue(data.search),
          };
        else delete newQuery.searchValue;
        recordQueryInRouter(newQuery);
      })
    : null;

  return (
    <ListTableStyle>
      <article>
        {title ? <h1>{title}</h1> : <></>}
        <section className="header">
          <div className="search-section">
            <div className="flex gap-[0.3125rem]">
              {filterDropDownProps && (
                <ListFilterDropDown
                  filterType={filterDropDownProps.filterType}
                  dropDownMenu={filterDropDownMenuObj}
                  onClickEvent={filterDropDownProps.onClickEvent}
                ></ListFilterDropDown>
              )}
              {searchForm && (
                <form onSubmit={searchFormSubmit}>
                  <SearchInput
                    placeholder={searchForm?.placeholder}
                    width={'25rem'}
                    defaultValue={
                      router?.query?.searchValue
                        ? decodeURIComponent(
                            router?.query?.searchValue as string,
                          )
                        : ''
                    }
                    hookFormRegister={searchForm?.searchRegister('search')}
                  ></SearchInput>
                </form>
              )}
            </div>

            {buttonInfo && (
              <div className="ml-6">
                {buttonInfo.type && buttonInfo.type === 'basic' ? (
                  <BasicButton
                    color={'blue'}
                    assetType={'Primary'}
                    size={'S'}
                    content={buttonInfo.content}
                    onClickEvent={buttonInfo.onClick}
                    isActive={!buttonInfo.disabled}
                  />
                ) : (
                  <IconButton
                    color={'blue'}
                    assetType={'Primary'}
                    size={'S'}
                    content={buttonInfo.content}
                    onClickEvent={buttonInfo.onClick}
                    isActive={!buttonInfo.disabled}
                    leftIcon={buttonInfo.icon ? buttonInfo.icon : <AddIcon />}
                  ></IconButton>
                )}
              </div>
            )}
            {dateCarousel && (
              <div className={`flex justify-end items-center relative`}>
                <DateCarousel
                  startDay={dateCarousel.start}
                  endDay={dateCarousel.end}
                  unit="day"
                  arrowOnClickEvent={({ startDay, endDay }) =>
                    router.replace({
                      pathname: router.pathname,
                      query: {
                        ...router.query,
                        [CommonListFilteringKey.START_DATETIME]:
                          startDay.unix(),
                        [CommonListFilteringKey.END_DATETIME]: endDay.unix(),
                      },
                    })
                  }
                  textOnClickEvent={() => setOpenCalendar(true)}
                />
                {dateCarousel.moveDataButton ? (
                  <MoveDateButtonStyle
                    onClick={() =>
                      dateCarousel.moveDataButton.onClickEvent &&
                      dateCarousel.moveDataButton.onClickEvent()
                    }
                  >
                    {dateCarousel.moveDataButton.content}
                  </MoveDateButtonStyle>
                ) : (
                  <></>
                )}
                {openCalendar && (
                  <div className={`absolute top-[110%] right-[0%] z-[90]`}>
                    <DateRangeCalendar
                      start={null}
                      end={null}
                      today={dayjs() ? dayjs().toDate() : null}
                      onChangeEvent={({ startDate, endDate }) => {
                        if (startDate && endDate) {
                          router.replace({
                            pathname: router.pathname,
                            query: {
                              ...router.query,
                              [CommonListFilteringKey.START_DATETIME]:
                                dayjs(startDate).unix(),
                              [CommonListFilteringKey.END_DATETIME]:
                                dayjs(endDate).unix(),
                            },
                          });
                          setOpenCalendar(false);
                          setTempStartDate(null);
                        } else if (startDate) {
                          setTempStartDate(dayjs(startDate));
                        }
                      }}
                      closeEvent={() => {
                        setOpenCalendar(false);
                      }}
                      maxDate={
                        tempStartDate
                          ? dateCarousel
                              .calculateMaxDate({
                                startDate: tempStartDate,
                                endDate: null,
                              })
                              .toDate()
                          : getEndOfISOWeek(dayjs()).toDate()
                      }
                    ></DateRangeCalendar>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
        <section className="relative table-wrapper">
          <table>
            <thead>
              <tr>
                {columnData.map((value, index) => {
                  const queryInRouter = tempQueryInRouter
                    ? tempQueryInRouter
                    : router.query;
                  return (
                    <th
                      key={value.text}
                      style={{
                        width: value.width,
                        minWidth: value.width,
                      }}
                      className={`relative`}
                    >
                      <div
                        className={`filtering-btn-wrapper ${
                          router.query[value.id] &&
                          router.query[value.id] !== 'desc' &&
                          router.query[value.id] !== 'asc'
                            ? 'text-my-blue-50'
                            : ''
                        } w-fit p-2 rounded-lg  ${
                          value.filterType
                            ? 'hover:bg-my-gray-90 cursor-pointer active:bg-my-gray-70'
                            : ''
                        }`}
                        style={{ justifyContent: value?.sort }}
                        onClick={
                          value.filterType
                            ? () => handleOnClickFilteringButton(value)
                            : null
                        }
                      >
                        <p>{value.text}</p>
                        {value.filterList &&
                        value.filterType === ListFilter.MULTI_SEARCH ? (
                          <button>
                            {openFilter === value.id ? (
                              <ArrowDropUpIcon
                                color={
                                  router.query[value.id] &&
                                  router.query[value.id] !== 'desc'
                                    ? 'var(--blue-50)'
                                    : 'var(--gray-10)'
                                }
                              ></ArrowDropUpIcon>
                            ) : (
                              <ArrowDropDownIcon
                                color={
                                  router.query[value.id] &&
                                  router.query[value.id] !== 'desc'
                                    ? 'var(--blue-50)'
                                    : 'var(--gray-10)'
                                }
                              ></ArrowDropDownIcon>
                            )}
                          </button>
                        ) : (
                          <></>
                        )}
                        {value.filterType === ListFilter.SORT ? (
                          <button>
                            {queryInRouter[value.id] ? (
                              queryInRouter[value.id] === 'asc' ? (
                                <AscendingIcon />
                              ) : (
                                <DescendingIcon />
                              )
                            ) : (
                              <ArrangeIcon />
                            )}
                          </button>
                        ) : (
                          <></>
                        )}
                        {value.filterType === ListFilter.SELECT ? (
                          <button>
                            {router.query[value.id] ? (
                              <ArrowDropUpIcon
                                color={
                                  router.query[value.id] &&
                                  router.query[value.id] !== 'desc'
                                    ? 'var(--blue-50)'
                                    : 'var(--gray-10)'
                                }
                              ></ArrowDropUpIcon>
                            ) : (
                              <ArrowDropDownIcon
                                color={
                                  router.query[value.id] &&
                                  router.query[value.id] !== 'desc'
                                    ? 'var(--blue-50)'
                                    : 'var(--gray-10)'
                                }
                              ></ArrowDropDownIcon>
                            )}
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                      {value.filterList &&
                      value.filterType === ListFilter.MULTI_SEARCH &&
                      openFilter === value.id ? (
                        <MultiSearchFilterBox
                          filterValueList={value.filterList}
                          name={value.id}
                          onClickCancel={() => setOpenFilter(null)}
                          defaultPickedOptionList={
                            typeof router.query[value.id] === 'string'
                              ? (router.query[value.id] as string).split(',')
                              : []
                          }
                          onSubmitEvent={(result) => {
                            let newQuery = {};
                            if (result[value.id].length > 0) {
                              const filterList = result[value.id].map((obj) =>
                                typeof obj === 'object' ? obj.id : obj,
                              );

                              newQuery = {
                                ...router.query,
                                pageNumber: 1,
                                [value.id]: encodeQueryValue(filterList),
                              };
                            } else {
                              newQuery = { ...router.query, pageNumber: 1 };
                              delete newQuery[value.id];
                            }
                            recordQueryInRouter(newQuery);
                          }}
                          className={
                            index / columnData.length > 0.5 ? 'right-0' : ''
                          }
                          pageType={router.pathname
                            .split('/')[2]
                            .replaceAll('-', '_')}
                        />
                      ) : (
                        <></>
                      )}
                      {value.filterType === ListFilter.SELECT &&
                      openFilter === value.id ? (
                        <SelectFilterBox
                          filterValueList={value.filterList}
                          onClickEvent={(obj) => {
                            const newQuery = {
                              ...router.query,
                              [value.id]: encodeQueryValue(obj.id),
                              pageNumber: 1,
                            };
                            if (obj.id === '') delete newQuery[value.id];
                            recordQueryInRouter(newQuery);
                            setOpenFilter(null);
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </th>
                  );
                })}
                {additionalFeature && (
                  <th style={{ width: '5%' }}>
                    <AdditionalFeature
                      menuTitle={additionalFeature.header.menuTitle}
                      menus={additionalFeature.header.getMenus()}
                      onSelect={({ selectedItem }) => {
                        recordQueryInRouter({
                          ...router.query,
                          pageNumber: 1,
                          [additionalFeature.routerKey]: selectedItem.id,
                        });
                        if (!_.isNil(additionalFeature.header.onSelect))
                          additionalFeature.header.onSelect();
                      }}
                    />
                  </th>
                )}
              </tr>
            </thead>
            {!rowData || rowData?.length === 0 ? (
              <tbody className="empty-table">{renderEmptyTable()}</tbody>
            ) : (
              <tbody>
                {rowData?.map((row, rowIndex) => {
                  return (
                    <tr
                      key={rowIndex}
                      onClick={() =>
                        clickRow && clickRow.onClick && !clickRow?.targetIdList
                          ? clickRow.onClick(row)
                          : ''
                      }
                      style={{
                        cursor: clickRow ? 'pointer' : '',
                        color: row.isHidden ? 'var(--gray-60)' : '',
                      }}
                    >
                      {columnData.map((column, columnIndex) => {
                        const customColumnIndex = customColumn.findIndex(
                          (data) => data.id === column.id,
                        );
                        if (customColumnIndex !== -1) {
                          const data = customColumn[customColumnIndex];
                          if (data.type === 'dropdown') {
                            return (
                              <td
                                style={{
                                  paddingLeft: '0.5rem',
                                  width: column.width,
                                  textAlign: column?.sort,
                                  cursor: clickRow?.targetIdList?.includes(
                                    column.id,
                                  )
                                    ? 'pointer'
                                    : '',
                                }}
                                key={columnIndex}
                                onClick={() =>
                                  clickRow &&
                                  clickRow.onClick &&
                                  clickRow?.targetIdList?.includes(column.id)
                                    ? clickRow.onClick(row)
                                    : ''
                                }
                              >
                                <ListDropdownFilterStyle
                                  width={'100%'}
                                  padding={'0.5rem'}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <BasicDropDown
                                    filterType={
                                      row[data.id] ??
                                      data.dropdownProps.defaultFilterType
                                    }
                                    dropDownMenu={data.dropdownProps.menu}
                                    onSelect={(item) =>
                                      data.dropdownProps.selectEvent(item, row)
                                    }
                                    icons={{
                                      open: (
                                        <ArrowDropUpIcon
                                          color={'var(--gray-10)'}
                                        ></ArrowDropUpIcon>
                                      ),
                                      close: (
                                        <ArrowDropDownIcon
                                          color={'var(--gray-10)'}
                                        ></ArrowDropDownIcon>
                                      ),
                                    }}
                                  ></BasicDropDown>
                                </ListDropdownFilterStyle>
                              </td>
                            );
                          } else if (data.type === 'timestamp') {
                            const timezone = data.getTimezoneFunction
                              ? data.getTimezoneFunction(row)
                              : null;
                            const dateTimeInfo = timeStampToDateTime(
                              row[data.id],
                              timezone,
                            );

                            return (
                              <td
                                style={{
                                  width: column.width,
                                  textAlign: column?.sort,
                                }}
                                key={columnIndex}
                              >
                                {dateTimeInfo
                                  ? `${dateTimeInfo.date} ${
                                      dateTimeInfo.time
                                    } (${
                                      dateTimeInfo.daysAgo !== 0
                                        ? dateTimeInfo.daysAgo + '일 전'
                                        : '오늘'
                                    })`
                                  : '-'}
                              </td>
                            );
                          } else if (data.type === 'buttonList') {
                            return (
                              <td
                                key={columnIndex}
                                style={{
                                  width: column.width,
                                  textAlign: column?.sort,
                                  cursor: clickRow?.targetIdList?.includes(
                                    column.id,
                                  )
                                    ? 'pointer'
                                    : '',
                                }}
                                onClick={() =>
                                  clickRow &&
                                  clickRow.onClick &&
                                  clickRow?.targetIdList?.includes(column.id)
                                    ? clickRow.onClick(row)
                                    : ''
                                }
                              >
                                <div
                                  className={`display flex gap-2 ${
                                    data.buttonBoxStyle && data.buttonBoxStyle
                                  }`}
                                >
                                  {data.buttonList.map(
                                    (buttonObj, buttonIndex) => {
                                      const role = buttonObj.getRoleOfButton({
                                        rowData: row,
                                      });
                                      if (role) {
                                        const buttonInfo =
                                          buttonObj.buttonInfoObj[`${role}`];

                                        if (buttonInfo.kind === 'original') {
                                          return (
                                            <BasicButton
                                              key={`${columnIndex}-${buttonIndex}`}
                                              color={buttonInfo.color}
                                              assetType={
                                                buttonInfo.type ?? 'Primary'
                                              }
                                              size={buttonInfo.size}
                                              content={buttonInfo.text}
                                              onClickEvent={() =>
                                                buttonInfo.onClickEvent({
                                                  rowData: row,
                                                })
                                              }
                                              isActive={
                                                typeof buttonInfo.active ===
                                                'boolean'
                                                  ? buttonInfo.active
                                                  : true
                                              }
                                              width={
                                                buttonInfo.width
                                                  ? buttonInfo.width
                                                  : null
                                              }
                                            ></BasicButton>
                                          );
                                        } else if (
                                          buttonInfo.kind === 'element'
                                        ) {
                                          return (
                                            <Fragment
                                              key={`${columnIndex}-${buttonIndex}`}
                                            >
                                              {buttonInfo.renderComponent({
                                                rowData: row,
                                              })}
                                            </Fragment>
                                          );
                                        }
                                      }
                                    },
                                  )}
                                </div>
                              </td>
                            );
                          } else if (data.type === 'element') {
                            return (
                              <td
                                style={{
                                  width: column.width,
                                  textAlign: column?.sort,
                                  cursor: clickRow?.targetIdList?.includes(
                                    column.id,
                                  )
                                    ? 'pointer'
                                    : '',
                                }}
                                key={columnIndex}
                                onClick={() =>
                                  clickRow &&
                                  clickRow.onClick &&
                                  clickRow?.targetIdList?.includes(column.id)
                                    ? clickRow.onClick(row)
                                    : ''
                                }
                              >
                                {data.renderComponent(row)}
                              </td>
                            );
                          }
                        } else {
                          return (
                            <td
                              style={{
                                width: column.width,
                                textAlign: column?.sort,
                                cursor: clickRow?.targetIdList?.includes(
                                  column.id,
                                )
                                  ? 'pointer'
                                  : '',
                              }}
                              key={columnIndex}
                              onClick={() =>
                                clickRow &&
                                clickRow.onClick &&
                                clickRow?.targetIdList?.includes(column.id)
                                  ? clickRow.onClick(row)
                                  : ''
                              }
                            >
                              {column.function
                                ? column.function(row[column.id])
                                : row[column.id] ?? '-'}
                            </td>
                          );
                        }
                      })}
                      {additionalFeature && (
                        <td style={{ width: '5%', padding: '0.5rem' }}>
                          <AdditionalFeature
                            rowItem={row}
                            menus={additionalFeature.body.getMenus(row)}
                            onSelect={additionalFeature.body.onSelect}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
          {isLoading && (
            <div className={`absolute top-0 left-0 z-[1000] w-full h-full`}>
              <LoadingSpinner height={'100%'} background="white" />
            </div>
          )}
        </section>
        {pageData && pageData.totalCount > 0 ? (
          <div className="flex justify-end">
            <BasicPagination
              count={Math.ceil(
                pageData.totalCount /
                  (pageData.pageSize ? pageData.pageSize : 15),
              )}
              variant="outlined"
              shape="rounded"
              className="pt-6"
              page={
                router.query.pageNumber ? Number(router.query.pageNumber) : 1
              }
              onChange={(e, value) => {
                if (
                  _.isNil(router.query[CommonListFilteringKey.PAGE_NUMBER]) ||
                  (router.query[CommonListFilteringKey.PAGE_NUMBER] &&
                    Number(router.query[CommonListFilteringKey.PAGE_NUMBER]) !==
                      value)
                ) {
                  recordQueryInRouter({
                    ...router.query,
                    pageNumber: value,
                  });
                }
              }}
              siblingCount={3}
            />
          </div>
        ) : (
          <></>
        )}
      </article>
      <style jsx global>{`
        .filtering-btn-wrapper {
          display: flex;
          gap: 0.25rem;
          position: relative;
          justify-content: flex-start;
          align-items: center;
          > button {
            display: flex;
            align-items: center;
          }
        }
      `}</style>
    </ListTableStyle>
  );
};

export default CommonList;
