import React, { useEffect, useState } from 'react';
import SideBarList from './SideBarList';
import {
  useAttendanceFilteringQueryStore,
  useManageQuestionFilteringQueryStore,
  useStatusFilteringQueryStore,
  useStudentListFilteringQueryStore,
} from '@store/filteringQueryStore';
import GridViewIcon, { FilledGridViewIcon } from '@asset/svg/GridViewIcon';
import PersonRaisedHandIcon, {
  FilledPersonRaisedHandIcon,
} from '@asset/svg/PersonRaisedHandIcon';
import GroupIcon, { FilledGroupIcon } from '@asset/svg/GroupIcon';
import BookIcon, { FilledBookIcon } from '@asset/svg/BookIcon';
import AssignmentTurnedInIcon, {
  FilledAssignmentTurnedInIcon,
} from '@asset/svg/AssignmentTurnedInIcon';
import TrophyIcon, { FilledTrophyIcon } from '@asset/svg/TrophyIcon';
import { makeQueryString } from '@asset/function/queryStringFunctions';
import { useUserInfoQuery } from '@apis/query/user';
import { MY_CLASS_MAIN_URL_PREFIX, MY_CLASS_URL_PREFIX } from 'routes/common';

const ManageStudentSideBar = () => {
  const { data: user } = useUserInfoQuery();
  const { urlQueryObj: studentListQueryStringObj } =
    useStudentListFilteringQueryStore();
  const { totalUrlQueryObj } = useStatusFilteringQueryStore();
  const { todayUrlQueryObj: attendanceQueryStringObj } =
    useAttendanceFilteringQueryStore();
  const { urlQueryObj: questionUrlQueryObj } =
    useManageQuestionFilteringQueryStore();

  const [studentListQueryString, setStudentListQueryString] = useState(null);
  const [totalStatusQueryString, setTotalStatusQueryString] = useState(null);
  const [todayAttendanceQueryString, setTodayAttendanceQueryString] =
    useState(null);
  const [quesitonQueryString, setQuestionQueryString] = useState(null);

  const defaultFiltering = user ? user.id.toString() : '';

  useEffect(() => {
    if (user) {
      const _queryString = makeQueryString(
        {},
        studentListQueryStringObj
          ? studentListQueryStringObj
          : { teacherName: defaultFiltering },
        [],
      );
      setStudentListQueryString(_queryString);
    }
  }, [user, studentListQueryStringObj]);

  useEffect(() => {
    if (user) {
      const _queryString = makeQueryString(
        {},
        attendanceQueryStringObj
          ? attendanceQueryStringObj
          : { teacherName: defaultFiltering },
        ['pageType'],
      );
      setTodayAttendanceQueryString(_queryString);
    }
  }, [user, attendanceQueryStringObj]);

  useEffect(() => {
    if (user) {
      const _queryString = makeQueryString(
        {},
        questionUrlQueryObj
          ? questionUrlQueryObj
          : { teacherName: defaultFiltering },
        [],
      );
      setQuestionQueryString(_queryString);
    }
  }, [user, questionUrlQueryObj]);

  useEffect(() => {
    if (user) {
      const _queryString = makeQueryString(
        {},
        totalUrlQueryObj ? totalUrlQueryObj : { teacherName: user.id },
        [],
      );
      setTotalStatusQueryString(_queryString);
    }
  }, [user, totalUrlQueryObj]);

  return (
    <SideBarList
      list={[
        {
          title: '나의 교실',
          link: MY_CLASS_MAIN_URL_PREFIX,
          keyword: [MY_CLASS_URL_PREFIX],
          icon: <GridViewIcon />,
          filledIcon: <FilledGridViewIcon />,
        },
        {
          title: '학생 목록',
          link: `/manage/student-list${studentListQueryString}`,
          keyword: ['/manage/student-list', '/manage/dashboard'],
          icon: <GroupIcon />,
          filledIcon: <FilledGroupIcon />,
        },
        {
          title: '학습 활동',
          link: `/manage/study-status-list/total${totalStatusQueryString}`,
          keyword: [
            '/manage/study-status-list/last',
            '/manage/study-status-list/total',
          ],
          icon: <BookIcon />,
          filledIcon: <FilledBookIcon />,
        },
        {
          title: '출석부',
          link: `/manage/student/attendance/today${todayAttendanceQueryString}`,
          keyword: ['/manage/student/attendance'],
          icon: <AssignmentTurnedInIcon />,
          filledIcon: <FilledAssignmentTurnedInIcon />,
        },
        {
          title: '질문',
          link: `/manage/student/question/list${quesitonQueryString}`,
          keyword: ['/manage/student/question/list'],
          icon: <PersonRaisedHandIcon />,
          filledIcon: <FilledPersonRaisedHandIcon />,
        },
        {
          title: '랭킹',
          link: `/manage/student/ranking`,
          keyword: ['/manage/student/ranking'],
          icon: <TrophyIcon />,
          filledIcon: <FilledTrophyIcon />,
        },
      ]}
    />
  );
};

export default ManageStudentSideBar;
