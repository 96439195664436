import React from 'react';

export const TriangleErrorIcon = ({
  width = '16',
  height = '16',
  color = 'var(--red-50)',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.85668 2.73914C7.37455 1.87602 8.62546 1.87602 9.14333 2.73914L14.7884 12.1476C15.3216 13.0363 14.6815 14.1669 13.6451 14.1669H2.35492C1.31853 14.1669 0.67838 13.0363 1.2116 12.1476L6.85668 2.73914Z"
        fill={color}
      />
      <path
        d="M7.99992 5.66602C7.64104 5.66602 7.35881 5.97274 7.38862 6.33038L7.6389 9.33383C7.65455 9.52159 7.81151 9.66602 7.99992 9.66602C8.18833 9.66602 8.34529 9.52159 8.36093 9.33383L8.61122 6.33038C8.64103 5.97274 8.3588 5.66602 7.99992 5.66602Z"
        fill="white"
      />
      <path
        d="M7.33325 11.666C7.33325 11.2978 7.63173 10.9993 7.99992 10.9993C8.36811 10.9993 8.66659 11.2978 8.66659 11.666C8.66659 12.0342 8.36811 12.3327 7.99992 12.3327C7.63173 12.3327 7.33325 12.0342 7.33325 11.666Z"
        fill="white"
      />
    </svg>
  );
};
